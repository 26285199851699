<template>
  <Collapsible
    title="Channels and Sources"
    :sub-title="`${sourceCount} applied`"
    :is-expand="isExpanding"
    :tags="sourceTag"
    @toggle="isExpanding = !isExpanding"
  >
    <template #title-suffix>
      <FilterTooltip>
        เลือกแสดงผลข้อความจากแหล่งข้อมูล
        <br /><br />
        ในกรณีที่มี Website เป็นจำนวนมาก ผู้ใช้งาน สามารถค้นหาเฉพาะที่ต้องการได้
        โดยพิมพ์ ชื่อ Website ในช่อง Search Source Name
      </FilterTooltip>
    </template>
    <template #expand>
      <ExpandableTreeSelect
        content="source"
        :tree-data="sourceTreeData"
        :selected="tempCriteria.rawSource"
        @change="onSourceSelect"
      />
    </template>
  </Collapsible>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';

import Collapsible from '../Collapsible.vue';
import ExpandableTreeSelect from '../ExpandableTreeSelect.vue';
import api from '@/services/api';
import FilterTooltip from './FilterTooltip.vue';

const allSources = api.getAllSource();
export default {
  components: {
    Collapsible,
    ExpandableTreeSelect,
    FilterTooltip,
  },
  setup() {
    const store = useStore();
    const tempCriteria = computed(() => store.getters['filter/tempCriteria']);
    const isExpanding = ref(false);
    const sourceTreeData = computed(
      () => store.getters['account/sourceTreeData']
    );
    const onSourceSelect = (obj) => {
      if (obj && obj.localState) {
        const srcObj = obj.localState;
        // Convert
        const socialList = [];
        const websiteList = [];
        Object.keys(srcObj).forEach((srcName) => {
          if (srcName === 'Website') {
            // if select all convert to 'website' word
            if (
              srcObj.Website.length ===
              sourceTreeData.value.defaultState.Website.length
            ) {
              websiteList.push('website');
            } else {
              for (let c of srcObj.Website) {
                const splitted = c.split('::');
                websiteList.push(splitted[1]);
              }
            }
          } else if (
            srcObj[srcName] &&
            srcName !== 'all Channels' &&
            srcName !== 'website'
          ) {
            socialList.push(srcName);
          }
        });
        const finalList = [...socialList, ...websiteList];
        // console.log(finalList);
        store.dispatch('filter/updateTempCriteria', {
          rawSource: obj.localState,
          source: finalList,
        });
      }
    };

    const sourceCount = computed(() => {
      return sourceTag.value.filter((v) => v.key !== 'all Channels').length;
    });
    const sourceTag = computed(() => {
      const result = [];
      let sorted = [];
      if (tempCriteria.value.rawSource && sourceTreeData.value) {
        let webObj;
        Object.keys(tempCriteria.value.rawSource).forEach((k) => {
          if (k === 'Website') {
            const defaultLength = sourceTreeData.value.defaultState[k].length;
            const currentLength = tempCriteria.value.rawSource[k].length;
            if (currentLength > 0) {
              const isAll = currentLength === defaultLength;
              const text = `${k} ${isAll ? ': all ' : ''}(${currentLength})`;
              webObj = {
                name: k,
                text,
                key: k,
              };
            }
          } else {
            if (tempCriteria.value.rawSource[k]) {
              result.push({
                name: helper.capitalize(k),
                text: k === 'twitter' ? 'X (Twitter)' : helper.capitalize(k),
                key: k,
              });
            }
          }
        });
        if (webObj) result.push(webObj);

        let selectAll = result.find(
          (v) => v.key.toLowerCase() === 'all channels'
        );

        if (selectAll) {
          sorted.push(selectAll);
        }

        allSources.forEach((n) => {
          let src = result.find((v) => v.key.toLowerCase() === n);
          if (src) {
            sorted.push(src);
          }
        });
      }

      return sorted;
    });

    return {
      isExpanding,
      sourceTreeData,
      sourceTag,
      onSourceSelect,
      tempCriteria,
      sourceCount,
    };
  },
};
</script>
