
import { SearchPresetType } from '@/types/preset-management/SearchPresetType';
import { reactive, watch } from 'vue';
export default {
  emits: ['dataChange'],
  name: 'PresetSearch',
  props: { presetCount: Number },
  setup(props, { emit }) {
    const data = reactive<SearchPresetType>({
      search: '',
      orderBy: 'DESC',
      isEnableCampaign: false,
    });

    watch(data, () => {
      emit('dataChange', data);
    });
    return { data };
  },
};
