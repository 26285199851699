import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d3a648d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AllPresetWrapper = _resolveComponent("AllPresetWrapper")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_PresetDetailWrapper = _resolveComponent("PresetDetailWrapper")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, {
      gutter: 24,
      class: "custom-height overflow-hidden custom-gap"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 24,
          xl: 8,
          class: "custom-height"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_spin, {
              spinning: $setup.isLoading,
              wrapperClassName: "custom-height spinner-container"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AllPresetWrapper, { onSelect: $setup.onSelect }, null, 8, ["onSelect"])
              ]),
              _: 1
            }, 8, ["spinning"])
          ]),
          _: 1
        }),
        ($setup.selectedPreset)
          ? (_openBlock(), _createBlock(_component_a_col, {
              key: 0,
              class: "custom-height",
              span: 24,
              xl: 16
            }, {
              default: _withCtx(() => [
                ($setup.selectedPreset)
                  ? (_openBlock(), _createBlock(_component_PresetDetailWrapper, {
                      key: $setup.selectedPreset.id,
                      onReload: $setup.loadData
                    }, null, 8, ["onReload"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}