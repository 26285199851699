import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-align-right" }
const _hoisted_2 = { class: "action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PresetDetail = _resolveComponent("PresetDetail")!
  const _component_PresetFilterOption = _resolveComponent("PresetFilterOption")!
  const _component_CampaignDashboardSetting = _resolveComponent("CampaignDashboardSetting")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "Save New Preset",
    width: "620px",
    class: "add-preset",
    bodyStyle: { padding: 0 },
    onCancel: $setup.onCancel
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "light",
            onClick: $setup.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: $setup.onSave,
            disabled: $setup.isFormError
          }, {
            default: _withCtx(() => [
              _createTextVNode("Save")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_PresetDetail, {
        item: $setup.presetData.preset,
        onDataChange: $setup.onDetailChange
      }, null, 8, ["item", "onDataChange"]),
      _createVNode(_component_PresetFilterOption, {
        "filter-data": $setup.presetData.filter,
        class: "border-top",
        onDataChange: $setup.onFilterChange,
        "show-collapse": true,
        "is-collapse": true
      }, null, 8, ["filter-data", "onDataChange"]),
      _createVNode(_component_CampaignDashboardSetting, {
        item: $setup.presetData.campaign,
        class: "border-top",
        onDataChange: $setup.onCampaignChange,
        "show-share": false,
        "show-collapse": true,
        "is-collapse": false
      }, null, 8, ["item", "onDataChange"])
    ]),
    _: 1
  }, 8, ["onCancel"]))
}