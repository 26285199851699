
import { ConvertedPresetDataType } from '@/types/preset-management/PresetType';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { computed, PropType, toRef } from 'vue';

export default {
  name: 'PresetDetailHeader',
  props: {
    item: { type: Object as PropType<ConvertedPresetDataType>, required: true },
  },
  emits: ['copy-link', 'delete'],
  setup(props, { emit }) {
    const item = toRef(props, 'item');
    const favoriteIcon = computed(() => {
      return item.value.isFavorite ? faStar : farStar;
    });
    const favoriteIconColor = computed(() => {
      return item.value.isFavorite ? 'color-warning' : 'gray-500';
    });
    const onAction = ({ key }: { key: 'copy-link' | 'delete' }) => {
      emit(key, item.value);
    };
    function truncateText(text: string | undefined): string {
      if (text == undefined) {
        return '';
      } else {
        return text.length > 47 ? text.substring(0, 47) + '...' : text; // thai 50, eng 47
      }
    }
    return { favoriteIcon, favoriteIconColor, onAction, truncateText };
  },
};
