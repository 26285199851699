
import PresetDetailWrapper from './PresetDetail/PresetDetailWrapper.vue';
import AllPresetWrapper from './AllPreset/AllPresetWrapper.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { ConvertedPresetType } from '@/types/preset-management/PresetType';
export default {
  components: {
    // ConfirmModal,
    PresetDetailWrapper,
    AllPresetWrapper,
  },
  name: 'PresetManagement',
  setup() {
    const store = useStore();
    const open = ref(false);
    const isLoading = computed<boolean>(
      () => store.getters['presetFilter/isLoading']
    );
    const selectedPreset = computed<ConvertedPresetType>(() => store.getters['presetFilter/selected']);
    const onSelect = (preset: ConvertedPresetType) => {
      store.dispatch('presetFilter/select', preset);
    };

    const loadData = async () => {
      await store.dispatch('presetFilter/loadPresets');
    };

    return { open, selectedPreset, onSelect, loadData, isLoading, };
  },
};
