import utils from '@/services/utils';
import api from '../../services/api';

export default {
  namespaced: true,

  state: {
    isLoading: true,
    presets: [],
    selected: null,
    query: {
      isEnableCampaign: undefined,
      presetName: undefined,
    },
    isDirty: false,
    activePreset: null,
    modalCreateCampaignFromPreset: {
      visible: false,
    },
    myCampaigns: [],
    campaignShareWithMes: [],
    activeCampaignFilter: null,
    activeCampaign: null,
  },
  getters: {
    myCampaigns(state) {
      return state.myCampaigns;
    },
    campaignShareWithMes(state) {
      return state.campaignShareWithMes;
    },
    activeCampaign(state) {
      return state.activeCampaign;
    },
    activeCampaignFilter(state) {
      return state.activeCampaignFilter;
    },
    activePreset(state) {
      return state.activePreset;
    },
    selected(state) {
      return state.selected;
    },
    isLoading(state) {
      return state.isLoading;
    },
    allUniquePresets(state) {
      const allPresets = [
        ...state.presets,
        ...state.myCampaigns.map((v) => {
          return {
            id: v.presetId,
            presetName: v.presetName,
            presetDescription: v.presetDescription,
          };
        }),
        ...state.campaignShareWithMes.map((v) => {
          return {
            id: v.presetId,
            presetName: v.presetName,
            presetDescription: v.presetDescription,
          };
        }),
      ];
      return Array.from(new Set(allPresets));
    },
    convertedPresets(state) {
      return utils.convertPresets(state.presets);
    },
    presets(state) {
      return state.presets;
    },
    isDirty(state) {
      return state.isDirty;
    },
  },
  actions: {
    async loadPresets({ commit, state }) {
      commit('setIsLoading', true);
      const resultMyFilter = await api.getUserFilterPreset(state.query);
      // const filters = resultMyFilter.message.filter(
      //   (preset) => !preset.isEnableCampaign
      // );
      commit('setPresets', resultMyFilter.message);
      commit('setIsLoading', false);
    },
    select({ commit }, data) {
      commit('setSelected', data);
    },
    async addPreset(_, data) {
      return api.addUserFilterPreset(data);
    },
    async deletePreset(_, id) {
      return api.deleteUserFilterPreset(id);
    },
    setIsDirty({ commit }, data) {
      commit('setIsDirty', data);
    },
    async updatePreset(_, { id, data }) {
      return api.updateUserFilterPreset(id, data);
    },
    async changeState(_, { id, data }) {
      return api.changeStateUserFilterPreset(id, data);
    },
    setQuery({ commit }, data) {
      commit('setQuery', data);
    },
    async loadAndSelect({ dispatch, getters }, presetId) {
      let item;
      await dispatch('loadPresets');
      if (getters.convertedPresets.length > 0) {
        item = getters.convertedPresets.find((v) => v.id === presetId);
        if (item) {
          dispatch('setActivePreset', item);
        }
      }
      return item;
    },

    async loadAndSetSelected({ dispatch, getters }, presetId) {
      let item;
      await dispatch('loadPresets');
      if (getters.convertedPresets.length > 0) {
        item = getters.convertedPresets.find((v) => v.id === presetId);
        if (!item) {
          item = getters.convertedPresets[0];
        }
        dispatch('select', item);
      }
      return item;
    },
    setActivePreset({ commit }, data) {
      commit('setActivePreset', data);
    },
    toggleModalCreateCampaignFromPreset({ commit, state }) {
      commit(
        'toggleModalCreateCampaignFromPreset',
        !state.modalCreateCampaignFromPreset.visible
      );
    },
    async loadCampaigns({ commit }) {
      commit('setIsLoading', true);
      const result = await api.getUserPresetCampaign();
      const myCampaigns = result.message.filter(
        (campaign) => campaign.myCampaign
      );

      const shareWithMeFiltered = result.message.filter(
        (campaign) => !campaign.myCampaign
      );

      commit('setMyCampaigns', myCampaigns);
      commit('setCampaignShareWithMes', shareWithMeFiltered);
      commit('setIsLoading', false);
    },
    // TODO: Change Logic for Share with me Campaigns
    async loadShareCampaigns({ commit, state }) {
      commit('setIsLoading', true);
      const myResult = await api.getUserFilterPreset(state.query);
      const myResultIds = myResult.message.map((item) => item.id);
      const shareCampaignResult = await api.getUserPresetCampaign();
      const shareWithMeFiltered = shareCampaignResult.message.filter(
        (campaign) =>
          !myResultIds.includes(campaign.presetId) && !campaign.myCampaign
      );
      commit('setCampaignShareWithMes', shareWithMeFiltered);
      commit('setIsLoading', false);
    },
    setActiveCampaignFilter({ commit }, data) {
      commit('setActiveCampaignFilter', data);
    },
    setActiveCampaign({ commit }, data) {
      commit('setActiveCampaign', data);
    },
  },
  mutations: {
    setActivePreset(state, data) {
      state.activePreset = data;
    },
    setQuery(state, data) {
      state.query = data;
    },
    setPresets(state, data) {
      state.presets = data;
    },
    setSelected(state, data) {
      state.selected = data;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsDirty(state, data) {
      state.isDirty = data;
    },
    toggleModalCreateCampaignFromPreset(state, data) {
      state.modalCreateCampaignFromPreset.visible = data;
    },
    setMyCampaigns(state, data) {
      state.myCampaigns = data;
    },
    setCampaignShareWithMes(state, data) {
      state.campaignShareWithMes = data;
    },
    setActiveCampaignFilter(state, data) {
      // criteria only
      state.activeCampaignFilter = data;
    },

    setActiveCampaign(state, data) {
      state.activeCampaign = data;
    },
  },
};
