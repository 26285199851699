<template>
  <a-select
    v-model:value="contentTypeSelected"
    :getPopupContainer="(trigger) => trigger.parentNode"
    class="custom-select"
    mode="multiple"
    style="width: 100%"
    :options="contentTypeOptions"
    @change="onContentTypeSelect"
  >
  </a-select>
  <!-- <a-select class="detail-select" v-model:value="messageTypeSelected" mode="tags" style="width: 100%">
    <template #dropdownRender="{}">
      <a-checkbox-group
        class="checkbox-custom vertical"
        v-model:value="messageTypeSelected"
        name="checkboxgroup"
        :options="messageTypeOptions"
        @change="onChange"
      />
    </template>
  </a-select> -->
</template>

<script>
import { computed } from 'vue';

const defaultContentTypeSelected = ['text', 'photo', 'video'];
const defaultContentTypeOptions = [
  { label: 'Text', value: 'text' },
  { label: 'Photo', value: 'photo' },
  { label: 'Video', value: 'video' },
];

export default {
  emits: ['update:selected'],
  props: {
    selected: { type: Array, default: () => defaultContentTypeSelected },
  },
  setup(props, { emit }) {
    const contentTypeOptions = defaultContentTypeOptions;

    const onContentTypeSelect = (value) => {
      emit('update:selected', value);
    };

    const contentTypeSelected = computed({
      get: () => props.selected, // Get the prop value
      set: (value) => emit('update:selected', value),
    });

    return {
      onContentTypeSelect,
      contentTypeOptions,
      contentTypeSelected,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  :deep(.ant-select-selector) {
    min-height: 42px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
  }

  :deep(.ant-select-selection-item) {
    border: 0;
    background: rgba(240, 245, 255, 1);
    border-radius: 5px;
    color: rgba(90, 95, 125, 1);
  }
}
</style>
