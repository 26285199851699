
import { reactive, toRef, PropType, onMounted, ref } from 'vue';
import Collapsible from '@/components/Sidebar/Collapsible.vue';
import { PresetCampaignType } from '@/types/preset-management/PresetType';
import { UserType } from '@/types/users/UserType';
import CampaignShareUser from './CampaignShareUser.vue';
export default {
  name: 'CampaignDashboardSetting',
  emits: ['dataChange'],
  components: { Collapsible, CampaignShareUser },
  props: {
    showCollapse: { type: Boolean, default: false },
    isCollapse: { type: Boolean, default: false },
    showShare: { type: Boolean, default: true },
    item: {
      type: Object as PropType<PresetCampaignType>,
    },
    users: {
      type: Array as PropType<UserType[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const collapse = ref(props.isCollapse)

    const showCollapse = toRef(props, 'showCollapse');
    const toggleExpand = () => {
      if (showCollapse.value) {
        collapse.value = !collapse.value;
      }
    };
    // const users = [{ label: 'jedwiwat.s', value: 'jedwiwat.s' }];
    const data = reactive<PresetCampaignType>({
      isEnableCampaign: false,
      isShareCampaign: false,
      shareUser: [],
    });

    const onEnableCampaign = (isEnable:boolean) => {
      data.isEnableCampaign = isEnable
      emit('dataChange', data);
    };

    const onChangeUser = (info: PresetCampaignType) => {
      data.isShareCampaign = info.isShareCampaign;
      data.shareUser = info.shareUser;
      emit('dataChange', data);
    };

    onMounted(() => {
      if (props.item) {
        data.isEnableCampaign = props.item.isEnableCampaign;
        data.isShareCampaign = props.item.isShareCampaign;
        if (props.item.shareUser) {
          data.shareUser = props.item.shareUser;
        }
      }
    });
    return { data, collapse, toggleExpand, onChangeUser,onEnableCampaign };
  },
};
