
import { computed, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import FilterButton from '@/components/Button/FilterButton.vue';
// import DateRangePickerButton from '@/components/Button/DateRangePickerButton.vue';
import DateRangeButton from '@/components/Button/DateRangeButton.vue';
// import ExportButton from '@/components/Button/ExportButton.vue';
import FilterPresetSelect from '@/components/Selections/FilterPresetSelect.vue';
import moment from 'moment';
import AdvanceSearchWrapper from './AdvanceSearchWrapper.vue';
// import FileTypeIcon from '../Icon/FileTypeIcon.vue';
import DashboardSettingButton from '../Button/DashboardSettingButton.vue';
import ShareCampaignDashboardModal from '@/views/Preset/ShareCampaignDashboardModal.vue';
import CreateCampaignDashboardModal from '@/views/Preset/CreateCampaignDashboardModal.vue';
export default {
  components: {
    FilterButton,
    // DateRangePickerButton,
    DateRangeButton,
    // ExportButton,
    FilterPresetSelect,
    AdvanceSearchWrapper,
    // FileTypeIcon,
    DashboardSettingButton,
    ShareCampaignDashboardModal,
    CreateCampaignDashboardModal,
  },
  props: {
    maxWidth: { type: Boolean, default: false },
    hasBottomSubtitle: { type: Boolean, default: false },
    title: {
      type: String,
      default: '',
    },
    hasSubtitle: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    hasFilter: {
      type: Boolean,
      default: true,
    },
    hasDatePicker: {
      type: Boolean,
      default: true,
    },
    hasExport: {
      type: Boolean,
      default: false,
    },
    hasSort: {
      type: Boolean,
      default: true,
    },
    sortType: {
      type: String,
      default: 'normal',
    },
    hasCountSelect: {
      type: Boolean,
      default: true,
    },
    disabledExport: {
      type: Boolean,
      default: false,
    },
    isCampaign: {
      type: Boolean,
      default: false,
    },
    hasFilterButton: {
      type: Boolean,
      default: true,
    },
    hasAdvanceSearch: {
      type: Boolean,
      default: true,
    },
    hasFilterPreset: {
      type: Boolean,
      default: false,
    },
    hasDashboardSetting: {
      type: Boolean,
      default: false,
    },
    disableDateRange: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggleExport'],
  setup(props, { emit }) {
    const createCampaignKey = ref(1);
    const fileTypeOptions = [
      { version: 'report3.2', type: 'pdf', name: 'Report (PDF)' },
      { version: 'csv', type: 'csv', name: 'Raw data (CSV)' },
      { version: 'json', type: 'json', name: 'Raw data (JSON)' },
    ];
    const { title } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isShowShareExport = computed(
      () => route.name && ['custom-campaign'].includes(route.name as string),
    );

    const isOwnCampaign = computed(() => {
      return activeCampaign.value?.user === store.getters['account/userId'];
    });
    const isOpenSaveToCampaign = ref(false);

    const activeCampaign = computed(
      () => store.getters['presetFilter/activeCampaign'],
    );
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const isExpandingToolbar = ref(false);
    const role = computed(() => store.getters['account/userRole']);
    const tempCriteria = computed(() => store.getters['filter/tempCriteria']);
    const dateRanges = computed(() => {
      return [
        moment(tempCriteria.value.time.sinceDate),
        moment(tempCriteria.value.time.untilDate),
      ];
    });
    const isOpenShareDashboard = ref(false);

    const toggleExport = (e) => {
      emit('toggleExport', e);
    };

    const goToEditCampaign = () => {
      router.push(`/campaign/edit/${encodeURIComponent(title.value)}`);
    };

    const setFilterDate = (timeObj) => {
      store.dispatch('filter/updateTempCriteria', {
        time: timeObj,
      });
      store.dispatch('filter/applyFilter');
    };
    const onAction = (action: {
      key:
        | 'advance-export'
        | 'share-dashboard'
        | 'save-dashboard'
        | 'report3.2'
        | 'csv'
        | 'json';
    }) => {
      if (action.key === 'advance-export') {
        router.push('/export');
      } else if (action.key === 'share-dashboard') {
        isOpenShareDashboard.value = true;
      } else if (action.key === 'save-dashboard') {
        isOpenSaveToCampaign.value = true;
      } else if (['report3.2', 'csv', 'json'].includes(action.key)) {
        emit(
          'toggleExport',
          fileTypeOptions.find((type) => type.version === action.key),
        );
      }
    };
    const onCloseCreateCampaign = () => {
      setTimeout(() => {
        createCampaignKey.value = new Date().valueOf();
      }, 500);
    };
    return {
      onAction,
      isMobileScreen,
      isExpandingToolbar,
      toggleExport,
      goToEditCampaign,
      role,
      setFilterDate,
      dateRanges,
      isShowShareExport,
      isOpenShareDashboard,
      activeCampaign,
      fileTypeOptions,
      isOwnCampaign,
      isOpenSaveToCampaign,
      createCampaignKey,
      onCloseCreateCampaign,
    };
  },
};
