import { AdvanceFilterType } from '@/types/AdvanceSearchFilterType';
import api from './api';
import { PresetType } from '@/types/preset-management/PresetType';

export default {
  convertSourceToRawSource(sources: string[], defaultWebsiteSources: string[]) {
    const socialSources = api.getSocialSource();

    const selectedSources = {
      Website: [] as string[],
    };
    for (const source of sources) {
      if (socialSources.includes(source)) {
        selectedSources[source] = true;
      } else {
        selectedSources['Website'].push(`website::${source}`);
      }
    }

    const socialList: string[] = [];
    const websiteList: string[] = [];
    Object.keys(selectedSources).forEach((srcName) => {
      if (srcName === 'Website') {
        // if select all convert to 'website' word
        if (selectedSources.Website.length === defaultWebsiteSources.length) {
          websiteList.push('website');
        } else {
          for (const c of selectedSources.Website) {
            const [_, sourceName] = c.split('::');
            websiteList.push(sourceName);
          }
        }
      } else if (
        selectedSources[srcName] &&
        srcName !== 'all Channels' &&
        srcName !== 'website'
      ) {
        socialList.push(srcName);
      }
    });
    const finalList = [...socialList, ...websiteList];
    return {
      rawSource: selectedSources,
      source: finalList,
    };
  },
  convertCategoryToRawCategory(
    category: string[],
    subCategory: { level: string; category: string[] }[],
    categoryTreeData: any
  ) {
    const categoryAndLevel = {};
    for (const cat of categoryTreeData.tree) {
      const catLevel = Number(cat.level);
      if (catLevel === 0) {
        if (category.length > 0) {
          categoryAndLevel[cat.name] = category.map((cat) => `0::${cat}`);
        } else {
          categoryAndLevel[cat.name] = cat.children.map((v) => v.value);
        }
      } else {
        const subCat = subCategory.find(
          (cat) => Number(cat.level) === catLevel
        );
        if (subCat) {
          if (subCat.category.length > 0) {
            categoryAndLevel[cat.name] = subCat.category.map(
              (cat) => `${catLevel}::${cat}`
            );
          } else {
            categoryAndLevel[cat.name] = cat.children.map((v) => v.value);
          }
        } else {
          categoryAndLevel[cat.name] = cat.children.map((v) => v.value);
        }
      }
    }
    return categoryAndLevel;
  },
  prepareCriteria(filter: AdvanceFilterType, sourceTreeData, categoryTreeData) {
    const newFilter = { ...filter } as any;
    const { source, rawSource } = this.convertSourceToRawSource(
      newFilter.source,
      sourceTreeData.defaultState.Website
    );
    newFilter.rawSource = rawSource;
    newFilter.source = source;
    const rawCategory = this.convertCategoryToRawCategory(
      newFilter.category,
      newFilter.subCategory,
      categoryTreeData
    );
    newFilter.rawCategory = rawCategory;
    return newFilter;
  },
  convertPreset(data: PresetType) {
    if (!data) return null;

    return {
      id: data.id,
      user: data.user,
      username: data.username,
      preset: {
        id: data.id,
        presetName: data.presetName,
        presetDescription: data.presetDescription,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        isFavorite: data.isFavorite,
        link: data.link,
      },
      filter: JSON.parse(data.criteria),
      campaign: {
        isEnableCampaign: data.isEnableCampaign,
        shareUser: data.shareUser,
        isShareCampaign: data.isShareCampaign,
      },
    };
  },
  convertPresets(presets: PresetType[]) {
    return presets.map((data) => {
      return this.convertPreset(data);
    });
  },
};
