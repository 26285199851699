
import { PresetCampaignType } from '@/types/preset-management/PresetType';
import { UserType } from '@/types/users/UserType';
import { computed, PropType, reactive, watch } from 'vue';

export default {
  name: 'CampaignShareUser',
  props: {
    users: {
      type: Array as PropType<UserType[]>,
      default: () => [],
    },
    item: {
      type: Object as PropType<PresetCampaignType>,
      required: true,
    },
  },
  emits: ['dataChange'],
  setup(props, { emit }) {
    const userList = computed(() => {
      return props.users.map((user) => {
        return {
          label: user.username,
          value: user.id,
        };
      });
    });

    const data = reactive({
      isShareCampaign: props.item?.isShareCampaign,
      shareUser: props.item?.shareUser,
    });

    const update = () => {
      console.log('udpate....');
      emit('dataChange', { ...data });
    };

    watch(props.item, () => {
      data.isShareCampaign = props.item?.isShareCampaign;
      data.shareUser = props.item?.shareUser;
    });

    return { data, userList, update };
  },
};
