import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PresetFilterOption = _resolveComponent("PresetFilterOption")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "Dashboard Setting Detail",
    width: "620px",
    bodyStyle: { padding: 0 },
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PresetFilterOption, {
        "filter-data": $props.item.filter,
        class: "border-top",
        "show-collapse": true,
        "is-collapse": false
      }, null, 8, ["filter-data"])
    ]),
    _: 1
  }))
}