<template>
  <a-modal
    v-model:visible="isShowModal"
    :okType="'danger'"
    title="Create Campaign Dashboard"
    :destroyOnClose="true"
    @cancel="closeModal"
  >
    <div class="create-campaign-dashboard-modal-content">
      <div class="wrapper">
        <div class="desc">Select preset to create your campaign dashboard</div>
        <div>
          <div class="form-row-title">Preset</div>
          <div id="role-input" class="form-row-input">
            <a-select
              v-model:value="selectedPreset"
              :getPopupContainer="(trigger) => trigger.parentNode"
              class="select-create-campaign"
              placeholder="Select Preset"
            >
              <template #notFoundContent>
                <NoDataAspect title="No Saved Presets" />
              </template>

              <a-select-option
                v-for="preset of presetList.values()"
                :key="preset.id"
              >
                {{ preset.presetName }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="action-button-modal">
        <a-button type="light" :outlined="true" @click="closeModal">
          Cancel
        </a-button>
        <a-button
          type="primary"
          :disabled="disableCreateButton"
          @click="createCampaignFromPreset"
        >
          Create
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import NoDataAspect from '@/components/Error/NoDataAspect.vue';

export default {
  name: 'CreateCampaignFromPresetModal',
  components: {
    NoDataAspect,
  },
  setup() {
    const store = useStore();
    const isShowModal = computed(
      () => store.state.presetFilter.modalCreateCampaignFromPreset.visible
    );
    const presetList = computed(() => store.state.presetFilter.presets);
    const selectedPreset = ref(null);
    const disableCreateButton = computed(() => {
      return !selectedPreset.value;
    });

    const closeModal = () => {
      selectedPreset.value = null;
      store.dispatch('presetFilter/toggleModalCreateCampaignFromPreset');
    };

    const createCampaignFromPreset = async () => {
      console.log('createCampaignFromPreset');
      await store.dispatch('presetFilter/updatePreset', {
        id: selectedPreset.value,
        data: {
          isEnableCampaign: true,
        },
      });
      await store.dispatch('presetFilter/loadCampaigns')
      closeModal();
    };

    return {
      isShowModal,
      disableCreateButton,
      presetList,
      selectedPreset,
      closeModal,
      createCampaignFromPreset,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../config/theme/colors.json';
.action-button-modal {
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    font-weight: 400;
    font-size: 14px;
    height: 44px;
  }
  .cancel-button {
    background: #f8f9fb;
    color: #5a5f7d;
    margin-right: 8px;
    border: 1px solid #f1f2f6;
  }
}
.form-row-title {
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  margin-bottom: 10px;
}
.form-row-input {
  margin-bottom: 24px;

  .input-border {
    border-radius: 4px;
    height: 42px;
  }
}

.desc {
  color: $gray-600;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.no-data-in-aspect {
  min-height: 30px !important;
  :deep(.img-logo) {
    width: 150px !important;
  }
  :deep(.no-data-title) {
    font-size: 15px !important;
  }
}

.select-create-campaign {
  width: 100%;

  :deep(.ant-select-selector) {
    display: flex;
    align-items: center;
  }
}
</style>
