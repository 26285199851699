
import { computed, onBeforeMount, onBeforeUnmount, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import api from '@/services/api';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
import ExportCard from '@/views/Settings/ExportCard.vue';
import CampaignPerformance from './Performance/CampaignPerformance.vue';
import CampaignChannel from './Channel/CampaignChannel.vue';
import CampaignTrending from './Trending/CampaignTrending.vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import MenuTab from '../../components/Tab/MenuTab.vue';
import utils from '@/services/utils';
import { PresetType } from '@/types/preset-management/PresetType';
import { UserPresetCampaignType } from '@/types/preset-management/UserPresetCampaignType';

export default {
  name: 'Campaign',
  components: {
    CustomPageHeader,
    ExportCard,
    CampaignPerformance,
    CampaignChannel,
    CampaignTrending,
    // SeeMoreDialog,
    MenuTab,
  },
  setup() {
    const store = useStore();
    const route = computed(() => useRoute());
    const router = useRouter();
    const currentUserPresetCampaignId = computed(() => {
      const id = route.value.params.id;
      return id;
    });

    const isCustomCampaign = computed(() => {
      return route.value.name === 'custom-campaign';
    });

    const currentPresetFilter = ref<PresetType | null>(null)

    const setCurrentPreset = async () => {
      const res = await api.getUserPresetCampaignById(currentUserPresetCampaignId.value).catch(() => { })
      if (res) {
        const data = res.message as UserPresetCampaignType
        currentPresetFilter.value = data.preset
      }
    }

    const isOwner = computed(() => {
      const currentUserId = store.state.account.userInfo.id;
      return currentPresetFilter.value?.user === currentUserId;
    });

    const activeKey = ref('1');
    let mode = ref('engagement'); //engagement,mention,view
    let selectedDataAspect = ref('engagement');
    let activeInterval = ref();

    const tabMenuList = [
      {
        label: 'Performance',
        value: '1',
      },
      {
        label: 'Channel',
        value: '2',
      },
      {
        label: 'Trending',
        value: '3',
      },
    ];

    const modeOptions = [
      { label: 'Engagement', value: 'engagement' },
      { label: 'Mentioned', value: 'mention' },
      { label: 'View', value: 'view' },
    ];

    const userInfo = computed(() => store.getters['account/userInfo']);
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    const sourceNameList = computed(
      () => store.getters['account/sourceNameList']
    );
    const campaignLevel = computed(
      () => store.state.account.campaignMenuData.categoryLevel
    );
    // const category = computed(() => store.getters['config/categoryData']);
    const campaignName = computed(() => {
      if (isCustomCampaign.value) {
        return currentPresetFilter.value?.presetName
          ? `Campaign: ${currentPresetFilter.value.presetName}`
          : '';
      } else {
        return decodeURIComponent(route.value.params.id)
          ? decodeURIComponent(route.value.params.id).toLowerCase()
          : '';
      }
    });
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    let filterResult = ref();
    const campaign = ref({
      name: campaignName.value,
      level: campaignLevel.value,
    });

    const setActiveKey = (value) => {
      console.log('activeKey: ' + value);
      activeKey.value = value;
    };

    const handleModeClick = async (e) => {
      selectedDataAspect.value = e.key;
    };

    // Export
    let isOpenExportCard = ref(false);
    const toggleExport = (fileType) => {
      exportFile(fileType);
      isOpenExportCard.value = true;
    };

    const closeExportCard = () => {
      isOpenExportCard.value = false;
    };

    let generating = ref(false);

    const exportFile = async (fileType) => {
      generating.value = true;

      if (
        filterResult.value &&
        filterResult.value.source &&
        filterResult.value.source.length <= 0
      ) {
        filterResult.value.source = [...sourceNameList.value];
      }
      if (fileType.version === 'report3.2') {
        await api
          .newExport32(filterResult.value, fileType.type, null)
          .catch(() => { });
      } else {
        await api
          .newExport(filterResult.value, fileType.type, null)
          .catch(() => { });
      }

      await getExportStatus();
      activeInterval.value = setInterval(() => {
        getExportStatus();
      }, 5000);
    };

    // export card mini
    const userName = computed(() => store.getters['account/userName']);
    let exportHistory = ref([]);

    const getExportStatus = async () => {
      const result = await api
        .getExportStatus(userName.value, 1)
        .catch(() => { });
      if (result && result.message) {
        const { records } = result.message;
        exportHistory.value = records;

        const loading = exportHistory.value.some(
          (ele) =>
            ele.exported_status === 'new' ||
            ele.exported_status === 'generating'
        );

        if (!loading) {
          generating.value = false;
          if (activeInterval.value) {
            clearInterval(activeInterval.value);
          }
        } else {
          generating.value = true;
        }
      }
    };

    const exportData = computed(() => {
      return exportHistory.value;
    });

    // if file is downloading > disable button
    const isGenerating = computed(() => {
      return generating.value;
    });

    const checkActiveKey = computed(() => {
      if (activeKey.value === '2') {
        return false;
      } else {
        return true;
      }
    });

    const hasExport = computed(() => {
      if (userInfo.value && userInfo.value.id === '61e53d2e08f1d127489a051d') {
        return false;
      }
      return true;
    });

    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');

      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    const init = async (fv) => {
      await store.dispatch('account/getCampaignMenu');
      const campaignCat = [];
      let obj;
      if (campaign.value) {
        if (campaign.value.level === 0) {
          const categoryList = [];
          categoryList.push(campaign.value.name);
          filterResult.value = {
            ...fv,
            category: categoryList,
          };
        } else {
          for (let sub of fv.subCategory) {
            if (parseInt(sub.level) === campaign.value.level) {
              obj = {
                level: sub.level,
                category: [],
              };
              obj.category.push(campaign.value.name);
              campaignCat.push(obj);
            } else {
              campaignCat.push(sub);
            }
          }
          filterResult.value = {
            ...fv,
            subCategory: campaignCat,
          };
        }
      }
    };

    onBeforeUnmount(() => {
      if (activeInterval.value) {
        clearInterval(activeInterval.value);
      }
      store.dispatch('seemore/close');
    });

    watch(activeKey, () => {
      store.dispatch('filter/toggleSource', checkActiveKey.value);
      init(filterCriteria.value);
    });

    watch(
      () => selectedDataAspect.value,
      (previous) => {
        mode.value = previous;
      }
    );

    watch(
      () => filterCriteria.value,
      () => {
        if (currentPresetFilter.value) {
          console.log(
            'watch currentPresetFilter.value',
            currentPresetFilter.value
          );
          store.dispatch(
            'presetFilter/setActiveCampaign',
            utils.convertPreset(currentPresetFilter.value)
          );
          store.dispatch(
            'presetFilter/setActiveCampaignFilter',
            filterCriteria.value
          );

          init(filterCriteria.value);
        }
      }
    );

    watch(() => route.value.params.id, async () => {
      await setCurrentPreset()
    })

    watch(
      () => campaignName.value,
      async () => {
        if (campaignName.value) {
          campaign.value.name = campaignName.value;
          const presetCriteria = currentPresetFilter.value?.criteria
            ? JSON.parse(currentPresetFilter.value.criteria)
            : null;
          const criteria = presetCriteria
            ? presetCriteria
            : filterCriteria.value;
          store.dispatch('filter/updateTempCriteria', criteria);
          store.dispatch('filter/applyFilter');
        } else {
          router.push(route.value.fullPath);
        }
      }
    );

    watch(
      () => campaignLevel.value,
      () => {
        campaign.value.level = campaignLevel.value;
      }
    );

    onUnmounted(() => {
      clearInterval(activeInterval.value);
      store.dispatch('presetFilter/setActiveCampaign', null);
      store.dispatch('presetFilter/setActiveCampaignFilter', null);
    });

    onBeforeMount(() => {
      setCurrentPreset()
    })

    return {
      toggleExport,
      isOpenExportCard,
      isOwner,
      closeExportCard,
      isGenerating,
      getExportStatus,
      exportData,
      hasExport,
      onScroll,
      activeKey,
      isMobileScreen,
      mode,
      selectedDataAspect,
      modeOptions,
      handleModeClick,
      selectedModeStyle,
      unSelectedModeStyle,
      campaign,
      checkActiveKey,
      campaignName,
      filterResult,
      tabMenuList,
      setActiveKey,
      currentPresetFilter,
    };
  },
};
