<template>
  <a-popover
    overlayClassName="popover-filter popover-dark"
    :getPopupContainer="(trigger) => trigger.parentNode"
  >
    <template #content>
      <slot></slot>
    </template>
    <FeatherIcon
      type="info"
      size="14"
      color="#9299B8"
      class="cursor-pointer ml-1"
      @click="$emit('click')"
    />
  </a-popover>
</template>

<script>
export default {
  emits: ['click'],
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';

.popover-filter {
  max-width: '240px';
}
</style>
