<template>
  <div class="collapsible" :class="{ active: isExpand }">
    <div class="header">
      <slot v-if="$slots.title" name="title"></slot>
      <span class="header-text" v-else>
        <span class="title">
          {{ title }}
        </span>
        <span>
          <slot name="title-suffix"></slot>
        </span>
      </span>
      <span class="actions">
        <span class="subtitle">{{ subTitle }}</span>
        <span class="header-toggle" @click="emitToggle" v-if="showCollapse">
          <FeatherIcon v-if="isExpand" type="chevron-up" size="16" />
          <FeatherIcon v-else type="chevron-down" size="16" />
        </span>
      </span>
    </div>
    <div v-show="isExpand" class="content-expand">
      <slot name="expand"></slot>
    </div>
    <div v-show="!isExpand" class="content-collapse">
      <slot name="collapse">
        <a-tag v-for="c in tags" :key="c.name" class="s-tag">
          {{ c.text }}
        </a-tag>
      </slot>
    </div>
  </div>
</template>

<script>
import { toRef } from 'vue';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: [String, Number],
      default: '',
    },
    isExpand: Boolean,
    isTransform: Boolean,
    tags: {
      type: Array,
      default: () => [],
    },
    showCollapse: { type: Boolean, default: true },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const title = toRef(props, 'title');
    const isExpand = toRef(props, 'isExpand');
    const emitToggle = () => {
      emit('toggle', { title: title.value, value: !isExpand.value });
    };
    return {
      emitToggle,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';
.collapsible {
  padding: 20px 24px;
  border-bottom: 1px solid #f1f2f6;
  &.active {
    background-color: #fbfbfc;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .header-text {
      display: inline;
      line-height: 26px;
      user-select: none;
      .title {
        font-size: 13px;
        color: #272b41;
        font-weight: 500;
      }
    }
    :deep(.header-toggle) {
      cursor: pointer;
      color: #272b41;
      display: flex;
      i {
        width: 16px;
        height: 16px;
      }
    }
  }
  .subtitle {
    font-size: 12px;
    color: $primary-color;
    min-width: 60px;
  }
  .content-collapse {
    .s-tag {
      user-select: none;
    }
  }
}
.actions {
  display: flex;
  column-gap: 8px;
  align-items: center;
}
</style>
