
const modalTypes = ['info', 'warning', 'success', 'error'];
import { computed, toRefs } from 'vue';
export default {
  name: 'ConfirmModal',
  emits: ['close', 'confirm'],
  props: {
    type: {
      type: String, // error,success,warning,info
      required: true,
      validator: (value: string) => Object.values(modalTypes).includes(value),
    },
    title: {
      type: String,
      required: true,
    },
    okTitle: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { type: modalType } = toRefs(props);
    const onConfirm = () => {
      context.emit('confirm');
    };
    const closeConfirm = () => {
      context.emit('close');
    };

    const color = computed(() => {
      return `color-${modalType.value}`;
    });

    const btnColor = computed(() => {
      return `${modalType.value}-button`;
    });

    return { closeConfirm, onConfirm, color, btnColor };
  },
};
