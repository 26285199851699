<template>
  <span>
    <a-select-v4
      v-model:value="sentimentSelected"
      :getPopupContainer="(trigger) => trigger.parentNode"
      class="custom-select"
      mode="multiple"
      style="width: 100%"
      :options="sentimentOptions"
      @change="onSentimentSelect"
    >
      <template #tagRender="{ label, closable, onClose, option }">
        <a-tag
          :closable="closable"
          @close="onClose"
          class="ant-select-selection-item custom-tag align-items-center"
          :style="
            option.color
              ? {
                  'background-color': option.color,
                }
              : {}
          "
        >
          {{ label }}
        </a-tag>
      </template>
    </a-select-v4>
  </span>
  <!-- <a-select class="detail-select" v-model:value="messageTypeSelected" mode="tags" style="width: 100%">
    <template #dropdownRender="{}">
      <a-checkbox-group
        class="checkbox-custom vertical"
        v-model:value="messageTypeSelected"
        name="checkboxgroup"
        :options="messageTypeOptions"
        @change="onChange"
      />
    </template>
  </a-select> -->
</template>

<script>
import helper from '@/services/helper';
import { Select } from 'ant-design-vue-v4';
import { computed } from 'vue';

const defaultSentimentSelected = ['positive', 'neutral', 'negative'];
const defaultSentimentOptions = [
  { label: 'Positive', value: 'positive', color: 'rgba(222, 247, 239, 1)' },
  { label: 'Neutral', value: 'neutral', color: 'rgba(254, 188, 60, 0.15)' },
  { label: 'Negative', value: 'negative', color: 'rgba(255, 77, 79, 0.15)' },
];

export default {
  emits: ['onChangeSentiment', 'update:selected'],
  components: {
    'a-select-v4': Select,
  },
  props: { selected: { type: Array, default: () => defaultSentimentSelected } },
  setup(props, { emit }) {
    const sentimentOptions = defaultSentimentOptions;
    const onSentimentSelect = (value) => {
      emit('update:selected', value);
    };

    const sentimentSelected = computed({
      get: () => props.selected, // Get the prop value
      set: (value) => emit('update:selected', value),
    });

    return {
      onSentimentSelect,
      sentimentOptions,
      sentimentSelected,
      helper,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  :deep(.ant-select-selector) {
    min-height: 42px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
  }

  :deep(.ant-select-selection-item) {
    border: 0;
    background: rgba(240, 245, 255, 1);
    border-radius: 5px;
    color: rgba(90, 95, 125, 1);
    font-size: 14px;
  }
}
</style>
