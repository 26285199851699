
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { computed, PropType, toRef } from 'vue';
import moment from 'moment';
import { ConvertedPresetType } from '@/types/preset-management/PresetType';
export default {
  name: 'PresetItem',
  props: {
    item: {
      type: Object as PropType<ConvertedPresetType>,
      required: true,
    },
  },
  emits: ['copy-link', 'delete', 'favorite'],
  setup(props, { emit }) {
    const item = toRef(props, 'item');
    const favoriteIcon = computed(() => {
      return item.value.preset.isFavorite ? faStar : farStar;
    });
    const favoriteIconColor = computed(() => {
      return item.value.preset.isFavorite ? 'color-warning' : 'gray-500';
    });
    const onAction = ({ key }: { key: 'copy-link' | 'delete' | 'favorite' }) => {
      emit(key, item.value);
    };

    function truncateText(text: string | undefined, isHeader: Boolean): string {
      if (text == undefined) {
        return '';
      } else {
        if (isHeader) {
          return text.length > 62 ? text.substring(0, 62) + '...' : text; // thai 66, eng 62
        } else {
          return text.length > 138 ? text.substring(0, 138) + '...' : text; // thai 152, eng 138
        }
      }
    }

    return { favoriteIcon, favoriteIconColor, onAction, moment, truncateText };
  },
};
