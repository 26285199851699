
import { ref, watch, toRef, PropType, Ref } from 'vue';
import _ from 'lodash';
import AdvanceSearchOperatorModal from '../Modal/AdvanceSearchOperatorModal.vue';
import KeywordHowTo from '../KeywordHowTo.vue';
import { AdvanceSearchFilterType } from '@/types/AdvanceSearchFilterType';
export default {
  components: {
    AdvanceSearchOperatorModal,
    KeywordHowTo,
  },
  props: {
    filterData: {
      type: Object as PropType<AdvanceSearchFilterType>,
      required: true,
    },
  },
  emits: ['change', 'resetSearch'],
  setup(props, { emit }) {
    const visibleLearnMore = ref(false);
    const searchPopoverVisible = ref(false);
    const activeKey = ref('1');
    const containAllList: Ref<string[]> = ref([]);
    const containAnyList: Ref<string[]> = ref([]);
    const containNoneList: Ref<string[]> = ref([]);
    const usernameList = ref<string[] | []>([]);
    const usernameMode = ref('BEGINS_WITH');
    const usernameOption = ref('INCLUDE');

    const prepareAdvanceSearchStr = () => {
      let str = '';
      const allStr = containAllList.value.join('" AND "');
      const orStr = containAnyList.value.join('" OR "');
      const noneStr = containNoneList.value
        .map((word) => `-"${word}"`)
        .join(' AND ');
      if (containAllList.value.length > 0) {
        str = `("${allStr}")`;
      }
      if (containAnyList.value.length > 0) {
        if (str.length === 0) {
          str = `("${orStr}")`;
        } else {
          str = `${str} AND ("${orStr}")`;
        }
      }
      if (containNoneList.value.length > 0) {
        if (str.length === 0) {
          str = `${noneStr}`;
        } else {
          str = `${str} AND -("${noneStr}")`;
        }
      }
      return str;
    };

    const searchData = async () => {
      const filterData: AdvanceSearchFilterType = {
        keywordPhrase: '',
        excludeKeywordPhrase: '',
        username: [],
        excludeUsername: [],
        usernameMatchMode: 'BEGINS_WITH',

        containAllList: containAllList.value,
        containAnyList: containAnyList.value,
        containNoneList: containNoneList.value,
      };
      // Do prepare include data
      // (a AND b) AND (c OR d)

      filterData.keywordPhrase = prepareAdvanceSearchStr() || searchInput.value;
      // Do prepare username
      if (
        usernameMode.value === 'BEGINS_WITH' ||
        usernameMode.value === 'IS_EXACTLY'
      ) {
        filterData.usernameMatchMode = usernameMode.value;
      }

      if (usernameOption.value === 'INCLUDE') {
        // include
        filterData.username = usernameList.value;
        filterData.excludeUsername = [];
      } else {
        //exclude
        filterData.username = [];
        filterData.excludeUsername = usernameList.value;
      }
      // await store.dispatch('filter/updateTempCriteria', filterData);
      // await store.dispatch('filter/applyFilter');
      // await store.dispatch('filter/createQueryUrl');

      // clear and close
      // clearSearch(true);
      emit('change', filterData);
      close();
    };

    // Header search
    const searchInput = ref<string>('');
    const quickSearch = _.debounce(() => {
      const advStr = prepareAdvanceSearchStr();
      if (advStr && advStr !== searchInput.value) {
        // TODO Do sync back to adv search field
        // Example ("gg" AND "aa") AND ("uu" OR "xx") AND -("GG")
        // AND REGEX ("xxx" [AND "yyy"])
        // OR REGEX ("xxx" [OR "yyy"])
        // EXCLUDE REGEXP = -("xxx" AND "yyy")

        // if has only and
        containAllList.value = [];
        containAnyList.value = [];
        containNoneList.value = [];
      }
      // console.log('search ', searchInput.value);
      // emit('quickSearch', searchInput.value);
      searchData();
    }, 1500);
    const filterSearchData: Ref<AdvanceSearchFilterType> = toRef(
      props,
      'filterData',
    );

    watch(filterSearchData, () => {
      searchInput.value =
        filterSearchData.value.keywordPhrase || searchInput.value;
      usernameList.value = filterSearchData.value.username || [];

      usernameMode.value = filterSearchData.value.usernameMatchMode;
      usernameOption.value = 'INCLUDE';

      if (filterSearchData.value.username?.length > 0) {
        usernameList.value = filterSearchData.value.username;
        usernameOption.value = 'INCLUDE';
      } else if (filterSearchData.value.excludeUsername?.length > 0) {
        usernameList.value = filterSearchData.value.excludeUsername;
        usernameOption.value = 'EXCLUDE';
      }
      containAllList.value = filterSearchData.value.containAllList || [];
      containAnyList.value = filterSearchData.value.containAnyList || [];
      containNoneList.value = filterSearchData.value.containNoneList || [];

      searchInput.value = filterSearchData.value.keywordPhrase;
    });

    // watch(
    //   () => filterSearchInput.value,
    //   () => {
    //     searchInput.value = filterSearchInput.value || '';
    //     emit('quickSearch', searchInput.value);
    //   }
    // );

    const resetSearchInput = () => {
      resetAdvanceSearchKeywords();
      resetAdavancesearchUsername();
      searchInput.value = '';

      searchData();
      // emit('resetSearch', searchInput.value);
      // should i clear all list
      // store.dispatch('filter/updateTempCriteria', {
      //   keywordPhrase: searchInput.value,
      //   username: [],
      //   excludeUsername: [],
      // });
      // store.dispatch('filter/applyFilter');
      // const rawStoredFilter = localStorage.getItem('stored-filter');
      // if (rawStoredFilter) {
      //   store.dispatch('filter/createQueryUrl');
      // } else {
      //   clearQueryUrl();
      // }
    };
    const resetAdvanceSearchKeywords = () => {
      containAllList.value = [];
      containAnyList.value = [];
      containNoneList.value = [];
    };

    const resetAdavancesearchUsername = () => {
      usernameList.value = [];
      usernameMode.value = 'BEGINS_WITH';
      usernameOption.value = 'INCLUDE';
    };
    const clearSearch = (isClose) => {
      resetAdvanceSearchKeywords();
      resetAdavancesearchUsername();
      if (isClose) {
        close();
      }
    };

    // const clearQueryUrl = () => {
    //   const searchURL = new URL(window.location);
    //   if (searchURL.searchParams.get('shortId')) {
    //     searchURL.searchParams.delete('shortId');
    //     window.history.pushState({}, '', searchURL);
    //     window.location.reload();
    //   }
    // };

    const clearSearchAndApply = () => {
      clearSearch(true);
      // if has searchinput ? should it clear
      resetSearchInput();
    };

    const close = () => {
      searchPopoverVisible.value = false;
    };
    const showLearnMore = () => {
      visibleLearnMore.value = true;
    };
    const hideLearnMore = () => {
      visibleLearnMore.value = false;
      searchPopoverVisible.value = true;
    };

    const openSearch = () => {
      searchPopoverVisible.value = true;
    };

    return {
      searchPopoverVisible,
      activeKey,
      containAllList,
      containAnyList,
      containNoneList,
      usernameList,
      usernameMode,
      usernameOption,
      searchInput,
      quickSearch,
      searchData,
      resetSearchInput,
      clearSearch,
      clearSearchAndApply,
      close,
      showLearnMore,
      visibleLearnMore,
      hideLearnMore,
      openSearch,
    };
  },
};
