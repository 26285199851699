<template>
  <div class="date-range-button">
    <div class="date-range-btn-container">
      <FeatherIcon
        class="calendar-icon"
        type="calendar"
        size="14"
        color="#3371ff"
      />
      <a-range-picker
        :id="id"
        v-model:value="dateRangeData"
        :show-time="{ format: 'HH:mm' }"
        format="YYYY-MM-DD HH:mm"
        :placeholder="['Start Date', 'End Date']"
        :ranges="ranges"
        @change="onChange"
        @openChange="changeDisplayFormat"
        :disabled="disabled"
      />
      <FeatherIcon
        class="chevron-icon"
        type="chevron-down"
        size="14"
      />
    </div>
  </div>
</template>

<script>
import { ref, toRef, onMounted, computed, watch, toRefs, nextTick } from 'vue';

import moment from 'moment';
const eod = moment().endOf('day');

export default {
  name: 'DateRangeButton',
  props: {
    init: {
      type: Boolean,
      default: true,
    },
    displayFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
    dateRanges: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['dateChange'],
  setup(props, { emit }) {
    const { displayFormat } = toRefs(props);
    const id = computed(() => {
      return `datepicker-${new Date().valueOf()}`;
    });

    const isInit = toRef(props, 'init');
    const propsDateRanges = toRef(props, 'dateRanges');

    watch(propsDateRanges, () => {
      dateRangeData.value = propsDateRanges.value;
      changeDisplayFormat();
    });
    const dateRangeData = ref([
      moment().subtract(1, 'months').startOf('day'),
      eod,
    ]);

    const ranges = {
      Today: [moment().startOf('day'), eod],
      Yesterday: [
        moment().subtract(1, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day'),
      ],
      'This month': [moment().startOf('month'), moment().endOf('month')],
      'This year': [moment().startOf('year'), moment().endOf('year')],
      'Last month': [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ],
      'Last year': [
        moment().subtract(1, 'years').startOf('year'),
        moment().subtract(1, 'years').endOf('year'),
      ],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    };

    const onChange = (dates) => {
      if (dates && dates.length === 2) {
        const timeObj = {
          sinceDate: dates[0].toISOString(),
          untilDate: dates[1].toISOString(),
        };
        emit('dateChange', timeObj);
      }
    };

    onMounted(() => {
      if (propsDateRanges.value && propsDateRanges.value.length > 0) {
        dateRangeData.value = [
          moment(propsDateRanges.value[0]),
          moment(propsDateRanges.value[1]).endOf('day'),
        ];
      }

      // else if (filterCriteria.value && filterCriteria.value.time) {
      //   dateRangeData.value = [
      //     moment(filterCriteria.value.time.sinceDate),
      //     moment(filterCriteria.value.time.untilDate),
      //   ];
      // }
      else {
        console.log('No time filter init');
        dateRangeData.value = [
          moment().subtract(1, 'months').startOf('day'),
          eod,
        ];
      }
      if (isInit.value) {
        const dates = dateRangeData.value;
        const timeObj = {
          sinceDate: dates[0].toISOString(),
          untilDate: dates[1].toISOString(),
        };
        emit('dateChange', timeObj);
      }

      changeDisplayFormat();
    });

    // watch(
    //   () => filterCriteria.value,
    //   () => {
    //     const { sinceDate,untilDate } = dateRangeData.value;
    //     dateRangeData.value = [moment(sinceDate), moment(untilDate)];
    //     changeDisplayFormat();
    //   }
    // );

    const changeDisplayFormat = () => {
      nextTick(() => {
        const [sinceDate, untilDate] = dateRangeData.value;
        console.log(dateRangeData.value);
        const startEl = document.querySelector(
          `#${id.value} input:nth-child(1)`,
        );
        const endEl = document.querySelector(`#${id.value} input:nth-child(3)`);

        startEl.value = moment(sinceDate).format(displayFormat.value);
        endEl.value = moment(untilDate).format(displayFormat.value);
      });
    };

    return {
      onChange,
      ranges,
      dateRangeData,
      changeDisplayFormat,
      id,
    };
  },
};
</script>

<style lang="scss">
@import '../../config/theme/colors.json';

.date-range-button {
  .date-range-btn-container {
    padding: 0;
    position: relative;
    border-radius: 4px;
    background: #ffffff;
    border: solid 1px $border-color-normal;
    height: 40px;

    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      svg {
        width: 12px;
        height: 12px;
        margin-right: 2px;
        color: #3371ff;
      }

      &.calendar-icon {
        left: 12px;
      }

      &.chevron-icon {
        right: 12px;
      }
    }

    .ant-calendar-picker {
      margin: 0;

      .ant-calendar-picker-input {
        border: none;
        width: 100%;
        height: 38px;
        padding: 4px 28px;
        line-height: 28px;
        font-size: 16px;
        color: #5a5f7d;

        input:first-child {
          right: 12px;
        }

        input {
          width: calc(50% - 5px);
          font-size: 14px;
          font-weight: 500;

          &::placeholder {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.ant-calendar-picker-container {
  .ant-calendar-footer-btn .ant-tag {
    cursor: pointer;
  }
}
</style>
