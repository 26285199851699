
import PresetDetail from './PresetDetail.vue';
import PresetDetailHeader from './PresetDetailHeader.vue';
import PresetFilterOption from './PresetFilterOption.vue';
import CampaignDashboardSetting from './CampaignDashboardSetting.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import api from '@/services/api';
import { notification } from 'ant-design-vue';
import { ConvertedPresetType } from '@/types/preset-management/PresetType';
import moment from 'moment';
import ConfirmModal from '@/components/Modal/ConfirmModal.vue';
import { UserType } from '@/types/users/UserType';
import { useStore } from 'vuex';
import _ from 'lodash';
export default {
  name: 'PresetDetailWrapper',
  components: {
    PresetDetail,
    PresetDetailHeader,
    PresetFilterOption,
    CampaignDashboardSetting,
    ConfirmModal,
  },
  setup() {
    const store = useStore();
    const showConfirmDelete = ref(false);
    const isValidDetail = ref(false)
    const item = computed(()=>{
      return store.getters['presetFilter/selected'];
    })
    const presetData = reactive<ConvertedPresetType>({
      id: item.value.id,
      preset: { presetDescription: '', presetName: '' },
      filter: {
        category: [],
        subCategory: [],
        source: [],
        ownMedia: [],
        messageType: [],
        sentiment: [],
        contentType: [],
        time: {
          sinceDate: moment().subtract(1, 'months').toISOString(),
          untilDate: moment().toISOString(),
        },
        matchCategoryMode: 'any',
        keywordPhrase: '',
        excludeKeywordPhrase: '',
        username: [],
        excludeUsername: [],
        usernameMatchMode: 'BEGINS_WITH',

        containAllList: [],
        containAnyList: [],
        containNoneList: [],
      },
      campaign: {
        isEnableCampaign: false,
        isShareCampaign: false,
        shareUser: [],
      },
    });
    const userList = ref<UserType[]>([]);

    const onSave = async () => {
      const res = await store.dispatch('presetFilter/updatePreset', {
        id: presetData.id,
        data: {
          criteria: JSON.stringify(presetData.filter),
          presetDescription: presetData.preset.presetDescription,
          presetName: presetData.preset.presetName,
          isEnableCampaign: presetData.campaign.isEnableCampaign,
          isShareCampaign: presetData.campaign.isShareCampaign,
          shareUser: presetData.campaign.shareUser,
        },
      });
      if (res) {
        notification.success({
          message: `${presetData.preset.presetName} has been saved successfully.`,
        });
      }
      reload();
    };
    const onCancel = () => {
      // reset
    };

    const onDetailChange = (preset) => {
      presetData.preset = preset;
      isValidDetail.value = preset.isValid
    };
    const onFilterChange = (filter) => {
      presetData.filter = filter;
    };
    const onCampaignChange = (campaign) => {
      presetData.campaign = campaign;
    };

    const onCopyLink = (convertedPreset: ConvertedPresetType) => {
      const link = `${window.location.origin}/share-preset/${convertedPreset.id}`;
      navigator.clipboard.writeText(link || '');
      notification.success({
        message: 'Link copied',
      });
    };

    const onConfirmDelete = async () => {
      const res = await store.dispatch(
        'presetFilter/deletePreset',
        presetData.id
      ).catch(() => {
        notification.error({
          message: `Failed to delete ${presetData.preset.presetName}.`,
        });
      });

      if (res) {
        notification.success({
          message: `${presetData.preset.presetName} has been deleted successfully.`,
        });
        showConfirmDelete.value = false;
        reloadAndSelect();
      }
    };

    const onDeletePreset = async () => {
      showConfirmDelete.value = true;
    };

    const onConfirmClose = () => {
      showConfirmDelete.value = false;
    };

    const loadUsers = async () => {
      const result = await api.getUserList();
      if (result && result.message) {
        userList.value = result.message;
      }
    };

    const setFormData = ()=>{
      presetData.id = item.value.id
      presetData.preset = _.cloneDeep(item.value.preset)
      presetData.campaign = _.cloneDeep(item.value.campaign)
      presetData.filter = _.cloneDeep(item.value.filter)
    }

    const reload = async () => {
      await store.dispatch('presetFilter/loadCampaigns');
      await store.dispatch('presetFilter/loadPresets');
    }
    const reloadAndSelect = async () => {
      await store.dispatch('presetFilter/loadCampaigns');
      await store.dispatch('presetFilter/loadAndSetSelected');
    }

    onMounted(() => {
      loadUsers();
      setFormData();
    });

    return {
      onSave,
      onCancel,
      onDetailChange,
      onFilterChange,
      onCampaignChange,
      onCopyLink,
      onDeletePreset,
      showConfirmDelete,
      onConfirmDelete,
      userList,
      onConfirmClose,
      isValidDetail,
      item,
    };
  },
};
