<template>
  <span>
    <ExportTreeSelect
      :show-icon="false"
      name="media"
      class="custom-select"
      :tree-data="mediaTreeDataComputed"
      :selected="mediaTreeSelected"
      :initially-check-all="false"
      @onChange="onMediaSelect"
      :show-search="true"
    ></ExportTreeSelect>
  </span>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
// import helper from '@/services/helper';

import ExportTreeSelect from '@/components/TreeSelect/ExportTreeSelect.vue';

export default {
  components: {
    ExportTreeSelect,
  },
  emits: ['onChangeMedia', 'update:selected'],
  props: { selected: { type: Array, default: () => [] } },
  setup(props, { emit }) {
    const store = useStore();
    const ownMediaData = computed(() => store.getters['config/ownMediaData']);
    const defaultMediaSelected = ref([]);

    const defaultChoices = computed(() =>
      ownMediaData.value.map((v) => {
        return { label: v.name, value: v.id };
      })
    );

    const onMediaSelect = (mediaList) => {
      console.log('onMediaSelect', mediaList);
      if (JSON.stringify(mediaList) !== JSON.stringify(props.selected)) {
        emit('update:selected', mediaList);
        console.log('change------');
      }
    };

    const mediaTreeDataComputed = computed(() => {
      if (defaultChoices.value && defaultChoices.value.length > 0) {
        const result = [];
        for (let data of defaultChoices.value) {
          let obj = {
            title: '',
            key: '',
            children: [],
            // slots: {
            //   title: 'title',
            // },
          };
          obj.title = data.label;
          obj.key = data.value;
          obj.value = obj.key;
          result.push(obj);
        }
        return result;
      }
      return [];
    });

    const mediaTreeSelected = computed(() => {
      const result = [];
      if (selectedValue.value && selectedValue.value.length > 0) {
        for (let data of selectedValue.value) {
          result.push(data);
        }
      }
      return result;
    });

    watch(defaultMediaSelected, () => {
      console.log(defaultMediaSelected);
    });

    onMounted(() => {
      console.log('mounted-own-media', props.selected);
    });

    const selectedValue = computed({
      get: () => props.selected,
      set: (value) => {
        if (value !== props.selected) {
          emit('update:selected', value); // Emit only if different
        }
      },
    });

    return {
      selectedValue,
      mediaTreeDataComputed,
      mediaTreeSelected,
      onMediaSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  :deep(.ant-select-selector) {
    min-height: 42px;
    border: 1px solid #e3e6ef;
    border-radius: 4px;
  }

  :deep(.ant-select-selection-item) {
    border: 0;
    background: rgba(240, 245, 255, 1);
    border-radius: 5px;
    color: rgba(90, 95, 125, 1);
  }
}
</style>
