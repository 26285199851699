<template>
  <ExportTreeSelect
    :show-icon="true"
    name="source"
    :initially-check-all="true"
    :tree-data="sourceTreeDataComputed"
    :selected="sourceTreeSelected"
    @onChange="onSourceSelect"
    :must-apply="true"
    :show-search="true"
  ></ExportTreeSelect>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
// import helper from '@/services/helper';

import ExportTreeSelect from '@/components/TreeSelect/ExportTreeSelect.vue';
import helper from '../../../services/helper';
import api from '@/services/api';

export default {
  components: {
    ExportTreeSelect,
  },
  emits: ['onChangeSource'],
  props: {
    selected: { type: Object },
  },
  setup(props, { emit }) {
    const store = useStore();
    const sourceTreeData = computed(
      () => store.getters['account/sourceTreeData'],
    );

    const onSourceSelect = (sList) => {
      let result = [];
      let sourceList = [...sList];
      let haveWebsite = false;
      for (let source of sourceList) {
        if (source) {
          const splitted = source.split('::');
          if (splitted && splitted[1]) {
            result.push(splitted[1]);
            haveWebsite = true;
          } else if (splitted && splitted[0]) {
            result.push(splitted[0]);
          }
        }
      }
      if (haveWebsite) {
        result = result.filter((source) => source !== 'website');
      }
      emit('onChangeSource', result);
    };

    const sourceTreeDataComputed = computed(() => {
      if (sourceTreeData.value && sourceTreeData.value.tree) {
        const result = [];
        for (let data of sourceTreeData.value.tree) {
          if (data.id !== 'allChannels') {
            let obj = {
              title: '',
              key: '',
              children: [],
              icon: '',
              // slots: {
              //   title: 'title',
              // },
            };
            obj.title = helper.capitalize(data.name);
            // obj.value = data.children && data.children.length ? '' : data.name;
            // obj.key = data.children && data.children.length ? '' : data.name.toLowerCase();
            obj.icon = data.name;

            // obj.value = data.name;
            obj.key = data.name.toLowerCase();
            obj.value = data.name.toLowerCase();
            obj.hasChild = data.children && data.children.length > 0;

            if (data.children && data.children.length) {
              for (let child of data.children) {
                obj.children.push({
                  title: child.label,
                  value: child.value,
                  key: child.value,
                });
              }
            }

            result.push(obj);
          }
        }
        return result;
      }
      return [];
    });

    const sourceTreeSelected = computed(() => {
      if (selectedValue.value?.length > 0) {
        const socialSources = api.getSocialSource();

        let sources = [];
        for (const source of selectedValue.value) {
          if (!socialSources.includes(source)) {
            sources.push(`website::${source}`);
          } else {
            sources.push(source);
          }
        }
        return sources;
      } else if (sourceTreeData.value && sourceTreeData.value.tree) {
        const result = [];
        for (let data of sourceTreeData.value.tree) {
          if (data.id === 'allChannels') continue;

          if (data.children && data.children.length) {
            for (let child of data.children) {
              result.push(child.value);
            }
          } else {
            result.push(data.name);
          }
        }
        return result;
      }
      return [];
    });

    const selectedValue = computed(() => props.selected);

    return {
      sourceTreeData,
      sourceTreeDataComputed,
      sourceTreeSelected,
      onSourceSelect,
    };
  },
};
</script>

<style lang="scss" scoped></style>
