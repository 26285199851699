import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a0f2631"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-title" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "d-flex flex-align-right border-top footer" }
const _hoisted_4 = { class: "action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PresetDetailHeader = _resolveComponent("PresetDetailHeader")!
  const _component_PresetDetail = _resolveComponent("PresetDetail")!
  const _component_PresetFilterOption = _resolveComponent("PresetFilterOption")!
  const _component_CampaignDashboardSetting = _resolveComponent("CampaignDashboardSetting")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    class: "preset-detail-wrapper h-100",
    bodyStyle: { height: '100%', display: 'flex', 'flex-direction': 'column' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_PresetDetailHeader, {
          item: $setup.item.preset,
          onCopyLink: $setup.onCopyLink,
          onDelete: $setup.onDeletePreset
        }, null, 8, ["item", "onCopyLink", "onDelete"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PresetDetail, {
          class: "border-top",
          item: $setup.item.preset,
          onDataChange: $setup.onDetailChange,
          "first-validate": true
        }, null, 8, ["item", "onDataChange"]),
        _createVNode(_component_PresetFilterOption, {
          class: "border-top",
          onDataChange: $setup.onFilterChange,
          "show-collapse": false,
          "is-collapse": false,
          "filter-data": $setup.item.filter
        }, null, 8, ["onDataChange", "filter-data"]),
        _createVNode(_component_CampaignDashboardSetting, {
          class: "border-top",
          item: $setup.item.campaign,
          users: $setup.userList,
          onDataChange: $setup.onCampaignChange,
          "show-collapse": false,
          "is-collapse": false
        }, null, 8, ["item", "users", "onDataChange"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_button, {
            type: "light",
            onClick: $setup.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: $setup.onSave,
            disabled: !$setup.isValidDetail
          }, {
            default: _withCtx(() => [
              _createTextVNode("Save Changes")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ])
      ]),
      _createVNode(_component_ConfirmModal, {
        "ok-title": "Delete",
        onClose: $setup.onConfirmClose,
        onConfirm: $setup.onConfirmDelete,
        type: "error",
        title: "Confirm Delete",
        visible: $setup.showConfirmDelete,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (($setup.showConfirmDelete) = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Are you sure to delete " + _toDisplayString($setup.item.preset.presetName) + " preset? ", 1)
        ]),
        _: 1
      }, 8, ["onClose", "onConfirm", "visible"])
    ]),
    _: 1
  }))
}