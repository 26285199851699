<template>
  <div class="message-action-bar">
    <div class="left-block">
      <a-button
        v-if="isDomAdmin"
        @click.stop="toggleRejectModal(true)"
      >
        <FeatherIcon
          class="mr-1"
          type="slash"
        />
        <span>Reject</span>
      </a-button>
    </div>
    <div class="right-block">
      <a-button
        v-if="isDomAdmin"
        :id="`alert-msg-${data.info.id}`"
        :data-clipboard-text="data.raw_data.redirect_url || data.raw_data.link"
        :class="{ success: data.analytic.alerted }"
        @click.stop="markAsAlert"
      >
        <FeatherIcon
          class="mr-1"
          type="bell"
        />
        <span v-if="data.analytic.alerted">Alerted</span>
        <span v-else>Mark as Alert</span>
      </a-button>

      <a-button
        :id="`read-msg-${data.info.id}`"
        :data-clipboard-text="data.raw_data.redirect_url || data.raw_data.link"
        :class="{ success: data.analytic.read }"
        @click.stop="confirmRead"
      >
        <FeatherIcon
          class="mr-1"
          type="book-open"
        />
        <span v-if="data.analytic.read">Already Read</span>
        <span v-else>Mark as Read</span>
      </a-button>

      <a-divider
        v-if="isDomAdmin"
        class="divider"
        type="vertical"
      ></a-divider>

      <a-button
        v-if="isDomAdmin"
        :class="{ success: data.analytic.reviewed }"
        @click.stop="confirmReview"
      >
        <FeatherIcon
          class="mr-1"
          type="check"
        />
        <span v-if="data.analytic.reviewed">Reviewed</span>
        <span v-else>Mark as Review</span>
      </a-button>
    </div>
  </div>
  <RejectMessage
    :visible="rejectVisible"
    :data="data"
    @close="onCloseReject"
  />
</template>

<script>
import { ref, toRefs, onMounted, onBeforeUnmount, inject } from 'vue';

import { notification } from 'ant-design-vue';
import ClipboardJS from 'clipboard';
import api from '@/services/api';
import RejectMessage from '@/components/Message/MessageMenu/RejectMessage.vue';

export default {
  components: {
    RejectMessage,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isDomAdmin: {
      type: Boolean,
    },
  },

  setup(props) {
    const { data } = toRefs(props);
    const onEditMessage = inject('onEditMessage');
    const showMode = inject('showMode');
    const rejectVisible = ref(false);

    let clipboardAlert = null;
    let clipboardRead = null;

    const toggleRejectModal = (state) => {
      rejectVisible.value = state;
    };

    const onCloseReject = (result) => {
      if (result) {
        onEditMessage({
          messageId: data.value.info.id,
          removed: true,
        });
      }
      toggleRejectModal(false);
    };

    const markAsAlert = async () => {
      const { id } = data.value.info;
      const { alerted } = data.value.analytic;
      let str = 'alert';
      let result;
      if (alerted) {
        result = await api.markAsUnalert(id).catch(() => {});
        str = 'not alert';
      } else {
        result = await api.markAsAlert(id).catch(() => {});
      }
      if (result) {
        let newValue = !alerted;
        if (showMode.value === 'alerted' && !newValue) {
          onEditMessage({
            messageId: id,
            removed: true,
          });
        } else if (showMode.value === 'notalerted' && newValue) {
          onEditMessage({
            messageId: id,
            removed: true,
          });
        } else {
          onEditMessage({
            messageId: id,
            alerted: !alerted,
          });
        }

        notification.success({
          message: 'Success',
          description: `Message will be ${str}`,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Message update alert status failed',
        });
      }
    };

    const confirmRead = async () => {
      const { id } = data.value.info;
      const { read } = data.value.analytic;
      let str = 'read';
      let result;
      if (read) {
        result = await api.markAsUnread(id).catch(() => {});
        str = 'not read';
      } else {
        result = await api.markAsRead(id).catch(() => {});
      }
      let newValue = !read;
      if (result) {
        console.log(showMode.value, newValue);
        // show review but unreview item = remove
        if (showMode.value === 'read' && !newValue) {
          onEditMessage({
            messageId: id,
            removed: true,
          });
        } else if (showMode.value === 'unread' && newValue) {
          onEditMessage({
            messageId: id,
            removed: true,
          });
        } else {
          onEditMessage({
            messageId: id,
            read: newValue,
          });
        }

        notification.success({
          message: 'Success',
          description: `Message set to ${str}`,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Message update read status failed',
        });
      }
    };

    const confirmReview = async () => {
      const { id } = data.value.info;
      const { reviewed } = data.value.analytic;
      let str = 'review';
      let result;
      if (reviewed) {
        result = await api.markAsUnreviewed(id).catch(() => {});
        str = 'not review';
      } else {
        result = await api.markAsReviewed(id).catch(() => {});
      }
      let newValue = !reviewed;
      if (result) {
        console.log(showMode.value, newValue);
        // show review but unreview item = remove
        if (showMode.value === 'reviewed' && !newValue) {
          onEditMessage({
            messageId: id,
            removed: true,
          });
        } else if (showMode.value === 'notreviewed' && newValue) {
          onEditMessage({
            messageId: id,
            removed: true,
          });
        } else {
          onEditMessage({
            messageId: id,
            reviewed: newValue,
          });
        }

        notification.success({
          message: 'Success',
          description: `Message set to ${str}ed`,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Message update review status failed',
        });
      }
    };

    onMounted(() => {
      if (data.value) {
        if (props.isDomAdmin) {
          const elemAlert = document.getElementById(
            `alert-msg-${data.value.info.id}`,
          );
          clipboardAlert = new ClipboardJS(elemAlert);
          clipboardAlert.on('success', () => {
            document.cookie = `lineincident-link=${data.value.raw_data.link};domain=.ask-dom.com`;
            notification.success({
              message: 'Success',
              description: 'Link copied to clipboard',
            });
          });

          clipboardAlert.on('error', () => {
            notification.error({
              message: 'Error',
              description: 'Cannot copy link url',
            });
          });
        }

        const elemRead = document.getElementById(
          `read-msg-${data.value.info.id}`,
        );
        clipboardRead = new ClipboardJS(elemRead);
        clipboardRead.on('success', () => {
          document.cookie = `lineincident-link=${data.value.raw_data.link};domain=.ask-dom.com`;
          notification.success({
            message: 'Success',
            description: 'Link copied to clipboard',
          });
        });

        clipboardRead.on('error', () => {
          notification.error({
            message: 'Error',
            description: 'Cannot copy link url',
          });
        });
      }
    });

    onBeforeUnmount(() => {
      if (clipboardAlert) {
        clipboardAlert.destroy();
      }

      if (clipboardRead) {
        clipboardRead.destroy();
      }
    });

    return {
      confirmRead,
      toggleRejectModal,
      onCloseReject,
      confirmReview,
      markAsAlert,
      rejectVisible,
    };
  },
};
</script>

<style lang="scss" scoped>
.message-action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #f1f2f6;
  overflow: auto;
  .ant-btn {
    background: #f4f5f7;
    font-size: 12px;
    line-height: 16px;
    height: 32px;
    padding: 8px 8px;
    &.success {
      background: #def7ef;
      color: #20c997;
    }
  }
  .right-block {
    display: flex;
    align-items: center;
    margin-left: 8px;
    column-gap: 8px;
    .divider {
      height: 16px;
      vertical-align: baseline;
    }
  }
}
</style>
