import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-508f7b54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaign-page" }
const _hoisted_2 = { class: "campaign-title-wrapper" }
const _hoisted_3 = { class: "page-tab-column" }
const _hoisted_4 = {
  key: 0,
  class: "mobile-title"
}
const _hoisted_5 = {
  key: 0,
  class: "page-title"
}
const _hoisted_6 = {
  key: 1,
  class: "page-title"
}
const _hoisted_7 = {
  key: 2,
  class: "page-title"
}
const _hoisted_8 = {
  key: 1,
  class: "filter-div"
}
const _hoisted_9 = {
  key: 0,
  class: "social-movement-filter-mode"
}
const _hoisted_10 = {
  key: 1,
  class: "mobile-social-movement-filter-mode"
}
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatherIcon = _resolveComponent("FeatherIcon")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_CustomPageHeader = _resolveComponent("CustomPageHeader")!
  const _component_MenuTab = _resolveComponent("MenuTab")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu_item_group = _resolveComponent("a-menu-item-group")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_CampaignPerformance = _resolveComponent("CampaignPerformance")!
  const _component_CampaignChannel = _resolveComponent("CampaignChannel")!
  const _component_CampaignTrending = _resolveComponent("CampaignTrending")!
  const _component_ExportCard = _resolveComponent("ExportCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomPageHeader, {
      isCampaign: true,
      hasSubtitle: false,
      hasExport: $setup.hasExport,
      hasFilter: $setup.isOwner,
      hasDatePicker: true,
      hasAdvanceSearch: $setup.isOwner,
      hasFilterButton: $setup.isOwner,
      disabledExport: $setup.isGenerating,
      disableDateRange: !$setup.isOwner,
      checkActiveKey: $setup.checkActiveKey,
      "has-dashboard-setting": !$setup.isOwner,
      onToggleExport: $setup.toggleExport
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString($setup.campaignName), 1),
          (!$setup.isOwner)
            ? (_openBlock(), _createBlock(_component_a_tooltip, {
                key: 0,
                placement: "bottom",
                overlayClassName: "tooltip-dark"
              }, {
                title: _withCtx(() => [
                  _createTextVNode("Created by " + _toDisplayString($setup.currentPresetFilter?.username), 1)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_FeatherIcon, {
                    type: "users",
                    size: "16",
                    color: "#3371ff"
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["hasExport", "hasFilter", "hasAdvanceSearch", "hasFilterButton", "disabledExport", "disableDateRange", "checkActiveKey", "has-dashboard-setting", "onToggleExport"]),
    _createElementVNode("div", {
      class: "page-content",
      onScroll: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.onScroll && $setup.onScroll(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MenuTab, {
          "tab-lists": $setup.tabMenuList,
          "tab-key": $setup.activeKey,
          onSetActiveKey: $setup.setActiveKey
        }, null, 8, ["tab-lists", "tab-key", "onSetActiveKey"]),
        ($setup.isMobileScreen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              ($setup.activeKey === '1')
                ? (_openBlock(), _createElementBlock("h5", _hoisted_5, "Performance"))
                : ($setup.activeKey === '2')
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_6, "Channel"))
                  : ($setup.activeKey === '3')
                    ? (_openBlock(), _createElementBlock("h5", _hoisted_7, "Trending"))
                    : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        ($setup.activeKey !== '2')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (!$setup.isMobileScreen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_a_radio_group, {
                      value: $setup.selectedDataAspect,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedDataAspect) = $event)),
                      "button-style": "solid",
                      size: "default"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.modeOptions, (mode) => {
                          return (_openBlock(), _createBlock(_component_a_radio_button, {
                            key: mode.value,
                            value: mode.value,
                            style: {"height":"34px"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(mode.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_a_dropdown, {
                      placement: "bottomRight",
                      getPopupContainer: (trigger) => trigger.parentNode
                    }, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, {
                          id: "mode-menu-mobile",
                          onClick: $setup.handleModeClick
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_menu_item_group, { title: "Measurement:" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.modeOptions, (mode) => {
                                  return (_openBlock(), _createBlock(_component_a_menu_item, {
                                    key: mode.value,
                                    style: _normalizeStyle($setup.selectedDataAspect === mode.value
                      ? $setup.selectedModeStyle
                      : $setup.unSelectedModeStyle
                      )
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(mode.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["style"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: require('@/assets/images/icon/icon-sliders.svg')
                        }, null, 8, _hoisted_11)
                      ]),
                      _: 1
                    }, 8, ["getPopupContainer"])
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]),
      ($setup.activeKey === '1')
        ? (_openBlock(), _createBlock(_component_CampaignPerformance, {
            key: 0,
            campaign: $setup.campaign,
            mode: $setup.selectedDataAspect,
            filterResult: $setup.filterResult
          }, null, 8, ["campaign", "mode", "filterResult"]))
        : _createCommentVNode("", true),
      ($setup.activeKey === '2')
        ? (_openBlock(), _createBlock(_component_CampaignChannel, {
            key: 1,
            filterResult: $setup.filterResult
          }, null, 8, ["filterResult"]))
        : _createCommentVNode("", true),
      ($setup.activeKey === '3')
        ? (_openBlock(), _createBlock(_component_CampaignTrending, {
            key: 2,
            campaign: $setup.campaign,
            mode: $setup.selectedDataAspect,
            filterResult: $setup.filterResult
          }, null, 8, ["campaign", "mode", "filterResult"]))
        : _createCommentVNode("", true)
    ], 32),
    ($setup.isOpenExportCard)
      ? (_openBlock(), _createBlock(_component_ExportCard, {
          key: 0,
          isGenerating: $setup.isGenerating,
          exportData: $setup.exportData,
          onToggleDownload: $setup.getExportStatus,
          onCloseExportCard: $setup.closeExportCard
        }, null, 8, ["isGenerating", "exportData", "onToggleDownload", "onCloseExportCard"]))
      : _createCommentVNode("", true)
  ]))
}