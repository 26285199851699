<template>
  <div class="text-center">
    <img :src="require(`@/assets/images/nodata/folder.svg`)" alt="no data" />
    <div class="gray-600-color fw-500 fs-15">
      No Group of Social Media Accounts
    </div>
    <div class="gray-500-color">
      Create a new group of social media accounts that you focus on
    </div>

    <div class="mt-2" v-if="isAdmin">
      <a href="/settings" target="_blank" class="primary-color fw-500"
        >+ Add Account Group</a
      >
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'NoSocialMediaAccountGroup',
  setup(props, { emit }) {
    const store = useStore();
    const role = computed(() => store.getters['account/userRole']);
    const isAdmin = computed(() => ['admin', 'domadmin'].includes(role.value));

    const goToSetting = () => {
      emit('navigate');
    };
    return { goToSetting, isAdmin };
  },
};
</script>
<style lang="scss" scoped>
@import '../../../config/theme/colors.json';

.primary-color {
  color: $primary-color;
}
.gray-500-color {
  color: $gray-500;
}
.gray-600-color {
  color: $gray-600;
}
.fs-15 {
  font-size: 15px;
}
</style>
