
import PresetHeader from './PresetHeader.vue';
import PresetSearch from './PresetSearch.vue';
import PresetList from './PresetList.vue';
import PresetItem from './PresetItem.vue';
import AddPreset from '../AddPreset.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { SearchPresetType } from '@/types/preset-management/SearchPresetType';
import { ConvertedPresetType } from '@/types/preset-management/PresetType';
import ConfirmModal from '@/components/Modal/ConfirmModal.vue';
import { useStore } from 'vuex';
import NoDataAspect from '@/components/Error/NoDataAspect.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import _ from 'lodash';

// const filterCriteria = {
//   category: ['beverages', 'breakfast', 'burger', 'collection', 'fried chicken', 'happy meal', 'mccafe', 'others', 'rice', 'set menu', 'test', 'test 2', 'ข', 'ฟ', 'เทส'],
//   subCategory: [
//     {
//       level: '1',
//       category: [
//         'alpha',
//         'beta',
//         'campaign - active',
//         'campaign - final',
//         'campaign - test',
//         'campaign - test active',
//         'campaign - test again',
//         'cleanliness',
//         'delta campaign',
//         'facility',
//         'food varieties',
//         'fresh',
//         'goods',
//         'keyword',
//         'marketing',
//         'others',
//         'price',
//         'promotion',
//         'quality',
//         'quantity',
//         'review',
//         'service',
//         'serving',
//         'test',
//         'แคมเปญ - ภาษาไทย',
//       ],
//     },
//     {
//       level: '2',
//       category: ['activity', 'branding', 'campaign', 'crisis', 'csr', 'delivery', 'membership', 'others', 'policy', 'presenter', 'privilege', 'product', 'promotion', 'recruitment'],
//     },
//     {
//       level: '3',
//       category: ['a-test', 'burgerking', 'gggg', 'mcdonald', 'mos burger', 'others', 'starbucks', 'texas chicken'],
//     },
//     {
//       level: '4',
//       category: ['others'],
//     },
//     {
//       level: '5',
//       category: ['others'],
//     },
//     {
//       level: '6',
//       category: ['others', 'project a'],
//     },
//   ],
//   source: ['facebook', '77kaoded'],
//   sentiment: ['positive', 'negative'],
//   matchCategoryMode: 'any',
//   contentType: ['text', 'photo', 'video'],
//   ownMedia: ['63da358cf8bebb0001158ea9'],
//   messageType: ['origin', 'comment'],
//   keywordPhrase: '("B") AND ("A") AND -("-"D"")',
//   time: {
//     sinceDate: '2024-11-09T17:00:00.000Z',
//     untilDate: '2024-12-11T16:59:59.999Z',
//   },
//   excludeKeywordPhrase: '',
//   username: ['test'],
//   excludeUsername: [],
//   usernameMatchMode: 'BEGINS_WITH',
//   containAllList: ['B'],
//   containAnyList: ['A'],
//   containNoneList: ['D'],
//   paging: {
//     recordPerPage: 100,
//     page: 1,
//   },
//   sort: {
//     field: 'engagement_score',
//     direction: 'desc',
//   },
//   highlight: {
//     enable: true,
//   },
// };

export default {
  components: {
    PresetHeader,
    PresetSearch,
    PresetList,
    PresetItem,
    AddPreset,
    ConfirmModal,
    NoDataAspect,
    NoDataFound,
  },
  emits: ['select'],
  setup(props, { emit }) {
    const showConfirmDelete = ref(false);
    const showAddPreset = ref(false);
    const selectedItemFromStore = computed<ConvertedPresetType | null>(
      () => store.getters['presetFilter/selected']
    );
    const isLoading = computed<boolean>(
      () => store.getters['presetFilter/isLoading']
    );
    const selectedItem = ref<ConvertedPresetType | null>(null);
    const addPresetKey = ref(0);
    // const items: PresetType[] = [
    //   {
    //     id: '1',
    //     favorite: true,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-01T12:00:00.000Z',
    //     isShareCampaign: true,
    //     isEnableCampaign: true,
    //     shareUser: [],
    //     link: 'https://test.com',
    //     criteria: JSON.stringify(filterCriteria),
    //   },
    //   {
    //     id: '2',
    //     favorite: false,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-02T10:00:00.000Z',
    //     isShareCampaign: false,
    //     isEnableCampaign: false,
    //     shareUser: [],
    //     link: 'https://222.com',
    //     criteria: JSON.stringify({
    //       ...filterCriteria,
    //       keywordPhrase: '("BX") AND ("Y") AND -("-"Z"")',
    //       time: {
    //         sinceDate: '2024-12-09T17:00:00.000Z',
    //         untilDate: '2024-12-11T16:59:59.999Z',
    //       },
    //       excludeKeywordPhrase: '',
    //       username: ['test2'],
    //       excludeUsername: [],
    //       usernameMatchMode: 'BEGINS_WITH',
    //       containAllList: ['X'],
    //       containAnyList: ['Y'],
    //       containNoneList: ['Z'],
    //     }),
    //   },
    //   {
    //     id: '3',
    //     favorite: true,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-01T12:00:00.000Z',
    //     isShareCampaign: true,
    //     isEnableCampaign: true,
    //     shareUser: [],
    //     link: 'https://test.com',
    //     criteria: JSON.stringify(filterCriteria),
    //   },
    //   {
    //     id: '4',
    //     favorite: true,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-01T12:00:00.000Z',
    //     isShareCampaign: true,
    //     isEnableCampaign: true,
    //     shareUser: [],
    //     link: 'https://test.com',
    //     criteria: JSON.stringify(filterCriteria),
    //   },
    //   {
    //     id: '5',
    //     favorite: true,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-01T12:00:00.000Z',
    //     isShareCampaign: true,
    //     isEnableCampaign: true,
    //     shareUser: [],
    //     link: 'https://test.com',
    //     criteria: JSON.stringify(filterCriteria),
    //   },
    //   {
    //     id: '6',
    //     favorite: true,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-01T12:00:00.000Z',
    //     isShareCampaign: true,
    //     isEnableCampaign: true,
    //     shareUser: [],
    //     link: 'https://test.com',
    //     criteria: JSON.stringify(filterCriteria),
    //   },
    //   {
    //     id: '7',
    //     favorite: true,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-01T12:00:00.000Z',
    //     isShareCampaign: true,
    //     isEnableCampaign: true,
    //     shareUser: [],
    //     link: 'https://test.com',
    //     criteria: JSON.stringify(filterCriteria),
    //   },
    //   {
    //     id: '8',
    //     favorite: true,
    //     presetName: 'Swensens - Mango 2024',
    //     presetDescription: 'Campaign มะม่วงอกร่องทอง ซันเด 2024',
    //     createdAt: '2024-12-02T10:00:00.000Z',
    //     updatedAt: '2024-12-01T12:00:00.000Z',
    //     isShareCampaign: true,
    //     isEnableCampaign: true,
    //     shareUser: [],
    //     link: 'https://test.com',
    //     criteria: JSON.stringify(filterCriteria),
    //   },
    // ];
    const store = useStore();
    const convertedPresets = computed<ConvertedPresetType[]>(
      () => store.getters['presetFilter/convertedPresets']
    );
    const searchData = reactive<SearchPresetType>({
      search: '',
      orderBy: 'DESC',
      isEnableCampaign: false,
    });
    const isSearch = computed<boolean>(() => {
      return (
        searchData.search.trim().length > 0 || searchData.isEnableCampaign === true
      );
    });

    const onAdd = () => {
      addPresetKey.value = new Date().valueOf();
      showAddPreset.value = true;
    };
    const onAdded = async (presetId: string) => {
      await store.dispatch('presetFilter/loadCampaigns');
      await store.dispatch('presetFilter/loadAndSetSelected', presetId);
    };
    const onSearch = _.debounce(async (data: SearchPresetType) => {
      searchData.isEnableCampaign = data.isEnableCampaign;
      searchData.orderBy = data.orderBy;
      searchData.search = data.search.trim();

      const presetName =
        searchData.search.length > 0 ? searchData.search : undefined;
      const isEnableCampaign =
        searchData.isEnableCampaign === true ? searchData.isEnableCampaign : undefined;
      store.dispatch('presetFilter/setQuery', {
        isEnableCampaign,
        presetName,
        sortDirection: data.orderBy,
      });
      await store.dispatch('presetFilter/loadPresets');
    }, 500);
    const onCopyLink = (preset: ConvertedPresetType) => {
      const link = `${window.location.origin}/share-preset/${preset.preset.id}`;
      navigator.clipboard.writeText(link || '');
      notification.success({
        message: 'Link copied',
      });
    };
    const onDelete = async (item: ConvertedPresetType) => {
      selectedItem.value = item;
      showConfirmDelete.value = true;
    };

    const onConfirmDelete = async () => {
      if (selectedItem.value) {
        const res = await store.dispatch(
          'presetFilter/deletePreset',
          selectedItem.value.id
        ).catch(() => {
          notification.error({
            message: `Failed to delete ${selectedItem.value.preset.presetName}.`,
          });
        });
        if (res) {
          notification.success({
            message: `${selectedItem.value.preset.presetName} has been deleted successfully.`,
          });
          showConfirmDelete.value = false;

          await store.dispatch('presetFilter/loadCampaigns');
          const item = await store.dispatch('presetFilter/loadAndSetSelected');
          onSelect(item)
        }
      }
    };

    const onFavorite = async (item: ConvertedPresetType) => {
      const favorite = !item.preset.isFavorite;
      item.preset.isFavorite = favorite;
      const res = await store.dispatch('presetFilter/changeState', {
        id: item.preset.id,
        data: { stateChange: favorite, typeChange: 'Favorite' },
      });
      if (res) {
        if (favorite) {
          notification.success({
            message: `${item.preset.presetName} favorited.`,
          });
        } else {
          notification.success({
            message: `${item.preset.presetName} removed from your favorited.`,
          });
        }
        item = await store.dispatch('presetFilter/loadAndSetSelected', item.id);
        onSelect(item)
      }
    };
    const onSelect = (item: ConvertedPresetType) => {
      selectedItem.value = item;
      emit('select', { ...item });
    };

    const listRef = ref(null);
    const onConfirmClose = () => {
      showConfirmDelete.value = false;
    };

    const handleScroll = async () => {
      // TODO: Handle Lazy load presetFilter/loadPresets
      /** To Resolve adhoc too much call loadPresets
      const element = listRef.value; // Access the DOM element
      if (element) {
        const atBottom =
          element.$el.scrollTop + element.$el.clientHeight >=
          element.$el.scrollHeight;
        if (atBottom) {
          await store.dispatch('presetFilter/loadPresets');
        }
      }*/
    };

    onMounted(async () => {
      const item = await store.dispatch('presetFilter/loadAndSetSelected');
      onSelect(item)
    });

    return {
      isLoading,
      isSearch,
      selectedItem,
      onAdd,
      onSearch,
      onCopyLink,
      onDelete,
      onFavorite,
      onSelect,
      showAddPreset,
      addPresetKey,
      onConfirmDelete,
      showConfirmDelete,
      convertedPresets,
      listRef,
      handleScroll,
      onConfirmClose,
      selectedItemFromStore,
      onAdded,
    };
  },
};
