
import { PropType } from 'vue';
import PresetFilterOption from './PresetDetail/PresetFilterOption.vue';
import { ConvertedPresetType } from '@/types/preset-management/PresetType';

export default {
  name: 'DashboardSettingDetail',
  components: { PresetFilterOption },
  props: {
    item: { type: Object as PropType<ConvertedPresetType>, required: true },
  },
  setup(_, { emit }) {
    const onCancel = () => {
      emit('update:visible', false);
    };

    return {
      onCancel,
    };
  },
};
