
import {
  ref,
  reactive,
  computed,
  watch,
  watchEffect,
  toRefs,
  onMounted,
  onUnmounted,
  provide,
  onBeforeMount,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';

import helper from '@/services/helper';
import Avatar from '@/components/Avatar.vue';
import FilterSidebar from '@/components/Sidebar/FilterSidebar.vue';
import AdvanceSearchWrapper from './AdvanceSearchWrapper.vue';
import CreateCampaignFromPresetModal from '@/views/Preset/CreateCampaignFromPresetModal.vue';
import { UserPresetCampaignType } from '@/types/preset-management/UserPresetCampaignType';

export default {
  components: {
    Avatar,
    FilterSidebar,
    AdvanceSearchWrapper,
    CreateCampaignFromPresetModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute()

    const filterShow = ref('');
    provide('showMode', filterShow);

    // Filter Sidebar
    const filterCollapsed = computed(() => store.state.filter.filterCollapsed);
    const campaignMenuData = computed(
      () => store.state.account.campaignMenuData
    );
    const campaignName = computed(() =>
      decodeURIComponent(route.params.id)
        ? decodeURIComponent(route.params.id).toLowerCase()
        : ''
    );
    const closeFilter = () => {
      store.dispatch('filter/toggleFilter', true);
    };

    // Side nav
    const forceHideLargeScreen = ref(false);
    const isMobileScreen = ref(false);
    const isSmallScreen = ref(false);
    const sideNavCollapsed = ref(false);
    const sidebarMode = ref('inline');
    const state = reactive({
      selectedKeys: [] as string[],
      openKeys: [] as string[],
      preOpenKeys: [],
    });

    // CustomDashboard
    const myCampaignMenu = computed<UserPresetCampaignType[]>(() => store.state.presetFilter.myCampaigns);
    const campaignShareWithMe = computed<UserPresetCampaignType[]>(
      () => store.state.presetFilter.campaignShareWithMes
    );

    const onCollapse = (result) => {
      sideNavCollapsed.value = result;
      if (result) {
        sidebarMode.value = 'vertical';
      } else {
        sidebarMode.value = 'inline';
      }
      // console.log(sideNavCollapsed.value, sidebarMode.value);
    };
    // Width track // TODO make this global
    const windowWidth = ref(window.innerWidth);
    const onWidthChange = () => {
      windowWidth.value = window.innerWidth;
    };
    const sidebarCollapsedWidth = ref(76);
    const checkWidthVal = () => {
      if (windowWidth.value < 576) {
        isMobileScreen.value = true;
        isSmallScreen.value = false;
        sidebarCollapsedWidth.value = 0;
      } else if (windowWidth.value < 992) {
        sidebarCollapsedWidth.value = 76;
        isMobileScreen.value = false;
        isSmallScreen.value = true;
      } else {
        sidebarCollapsedWidth.value = 76;
        isMobileScreen.value = false;
        isSmallScreen.value = false;
      }
    };

    watch(
      () => windowWidth.value,
      () => checkWidthVal()
    );
    // Sidebar toggle
    const toggleCollapsed = (forceToggle = false) => {
      // console.log('M/S/F', isMobileScreen.value, isSmallScreen.value, forceToggle);
      if (isMobileScreen.value || (isSmallScreen.value && forceToggle)) {
        // mobile = no sidebar at all
        // force toggle is top toggle
        sideNavCollapsed.value = !sideNavCollapsed.value;
        // if toggle true set sidebar mode to inline ja
        if (sideNavCollapsed.value) {
          sidebarMode.value = 'vertical';
        } else {
          sidebarMode.value = 'inline';
        }
      } else if (isSmallScreen.value && !forceToggle) {
        // small screen normal toggle should not toggle type
        // sideNavCollapsed.value = !sideNavCollapsed.value;
      } else if (forceToggle) {
        if (forceHideLargeScreen.value) {
          // if true means its already collapse in large screen
          // then toggle it means to open it
          forceHideLargeScreen.value = false;
          sideNavCollapsed.value = false;
          sidebarMode.value = 'inline';
        } else {
          forceHideLargeScreen.value = true;
          sideNavCollapsed.value = true;
          sidebarMode.value = 'vertical';
        }
      }
    };

    const activeMenu = () => {
      // console.log('RCH', route.name);
      if ((isMobileScreen.value || isSmallScreen.value) && sideNavCollapsed) {
        // Bug fix ???
        state.openKeys = [];
      } else {
        let name = route.name as string
        if (
          name === 'overview' ||
          name === 'comparison' ||
          name === 'influencer' ||
          name === 'own-media'
        ) {
          state.openKeys = ['dashboard'];
        }
        if (name === 'campaign' || name === 'campaign comparison') {
          state.openKeys = ['campaign'];
        }
        if (
          name === 'setting-dashboard' ||
          name === 'keyword-setting' ||
          name === 'category-setting' ||
          name === 'source-setting'
        ) {
          state.openKeys = ['data-setting'];
        }

        if (
          name === 'custom-campaign'
        ) {
          const exist = myCampaignMenu.value.find(v => v.id === route.params.id)
          if (exist) {
            state.openKeys = ['my-campaign'];
            name = `my-campaign-${route.params.id}`
          }

          const shareExist = campaignShareWithMe.value.find(v => v.id === route.params.id)
          if (shareExist) {
            state.openKeys = ['share-with-me'];
            name = `share-campaign-${route.params.id}`
          }
        }
        state.selectedKeys = [name];
      }
      // console.log(route.meta);

      if (route.meta.enableScrollTop) {
        enableScrollTop.value = true;
      } else {
        enableScrollTop.value = false;
      }

      if (route.meta.hideSearch) {
        hideSearch.value = true;
      } else {
        hideSearch.value = false;
      }
    }
    watch(
      () => route.fullPath,
      () => {
        activeMenu()
      }
    );
    const scrollBar = ref(null);
    const setScroll = async () => {
      const { name } = route;
      if (
        name === 'setting-dashboard' ||
        name === 'keyword-setting' ||
        name === 'category-setting' ||
        name === 'source-setting'
      ) {
        scrollBar.value.$el.scrollTop = 0;
        scrollBar.value.$el.behavior = 'smooth';
      }
    };
    // watchEffect(() => {
    //   console.log('OPENKEYS?', router.value.matched);

    //   if (router.value.matched.length) {
    //     // TODO fix this !!
    //     state.selectedKeys = [router.value.matched[0].name];
    //     state.openKeys = [router.value.matched[0].name];
    //     state.preOpenKeys = [router.value.matched[0].name];
    //     // if (router.value.matched.length > 2) {
    //     //   state.selectedKeys = [router.value.matched[2].name];
    //     //   state.openKeys = [router.value.matched[1].name];
    //     //   state.preOpenKeys = [router.value.matched[1].name];
    //     // } else if (router.value.matched.length > 3) {
    //     //   state.selectedKeys = [router.value.matched[3].name];
    //     //   state.openKeys = [router.value.matched[1].name];
    //     //   state.preOpenKeys = [router.value.matched[1].name];
    //     // } else {
    //     //   state.selectedKeys = [router.value.matched[1].name];
    //     //   state.openKeys = [router.value.matched[1].name];
    //     //   state.preOpenKeys = [router.value.matched[1].name];
    //     // }
    //   }
    // });

    watchEffect(async () => {
      // Sync value to store
      const navLayoutData = {
        sideNavCollapsed: sideNavCollapsed.value,
        isSmallScreen: isSmallScreen.value,
        isMobileScreen: isMobileScreen.value,
      };
      await setScroll();
      // console.log('Effect trigger - sync nav to store', navLayoutData);
      store.dispatch('setNavLayoutData', navLayoutData);
    });

    // watch(
    //   () => state.openKeys,
    //   (val, oldVal) => {
    //     state.preOpenKeys = oldVal;
    //   }
    // );
    // Side nav val
    const brand = computed(() => store.state.account.brand);
    const accountProfileImage = computed(
      () => store.state.account.userAccount.accountImageURL
    );
    const username = computed(() => store.getters['account/userName']);
    const role = computed(() => store.getters['account/userRole']);
    const isUserCanAddCampaign = computed(() => {
      return !['accountmanager', 'user', 'feedonly'].includes(role.value);
    });
    const emailText = computed(() => store.getters['account/userEmail']);
    const usageVal = computed(() => {
      return helper.numeral(store.state.account.totalUsage);
    });
    const quotaVal = computed(() => {
      const { quota } = store.state.account.summary;
      if (quota && quota > 0) {
        return helper.numeral(quota);
      }
      return 'unlimited';
    });

    // Header search
    const hideSearch = ref(false);
    const searchInput = ref('');
    const quickSearch = _.debounce(() => {
      // console.log('search ', searchInput.value);
      store.dispatch('filter/updateTempCriteria', {
        keywordPhrase: searchInput.value,
      });
      store.dispatch('filter/applyFilter');
    }, 500);
    const filterSearchInput = computed(
      () => store.getters['filter/searchInput']
    );
    watch(
      () => filterSearchInput.value,
      () => {
        searchInput.value = filterSearchInput.value;
      }
    );
    const mobileSearchOpen = ref(false);

    // Signout
    const signOut = () => {
      store.dispatch('filter/clearFilter');
      localStorage.removeItem('category-name');
      window.location = '/login?out=1';
    };

    // Scroll to top
    let scrollElement;
    const enableScrollTop = ref(false);
    const hasScrollBottom = ref(false);
    const onPageScroll = _.debounce(() => {
      scrollElement = document.querySelector('#main-content .page-content');
      if (scrollElement && scrollElement.scrollTop > 200) {
        hasScrollBottom.value = true;
      } else {
        hasScrollBottom.value = false;
      }
    }, 250);

    const scrollToTop = () => {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    };

    onMounted(() => {
      window.addEventListener('resize', onWidthChange);
      checkWidthVal();
    });

    onBeforeMount(async () => {
      activeMenu()
      await store.dispatch('presetFilter/loadCampaigns');
      activeMenu()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', onWidthChange);
    });

    function showModalCreateCustomDashboard() {
      console.log('showModalCreateCustomDashboard');
      store.state.presetFilter.modalCreateCampaignFromPreset.visible = true;
    }

    return {
      myCampaignMenu,
      campaignShareWithMe,
      showModalCreateCustomDashboard,

      sidebarCollapsedWidth,
      isMobileScreen,
      isSmallScreen,
      sideNavCollapsed,
      toggleCollapsed,
      onCollapse,
      brand,
      username,
      role,
      emailText,
      usageVal,
      quotaVal,
      sidebarMode,
      filterCollapsed,
      closeFilter,
      ...toRefs(state),
      quickSearch,
      searchInput,
      mobileSearchOpen,
      hideSearch,
      signOut,
      scrollToTop,
      hasScrollBottom,
      enableScrollTop,
      onPageScroll,
      campaignMenuData,
      route,
      campaignName,
      scrollBar,
      accountProfileImage,
      isUserCanAddCampaign,
    };
  },
};
